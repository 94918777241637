.email-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__bold {
    font-weight: 700;
  }

  &__buttons {
    .tab-nav-buttons__button-next {
      min-width: 170px;

      &_left {
        margin-left: auto;
      }
    }

    .tab-nav-buttons__button {
      width: auto;

      .email-page__button-icon {
        svg {
          @include transition-standard();
          scale: 0.7;
          path {
            stroke: $white;
          }
        }
      }

      &.ant-dropdown-open {
        .email-page__button-icon {
          svg {
            transform: rotate(-180deg);
          }
        }
      }
    }
  }

  &__button-wrapper {
    margin-bottom: 32px;
  }

  &__content {
    width: 100%;
    height: 100%;
  }

  &__loader-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__tabs {
    .ant-tabs-nav {
      margin-bottom: 16px;
    }
  }
}
