.change-main-subscriber {
  &__button-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__wrapper {
    .ant-modal-header {
      padding-bottom: 0;
    }

    .ant-modal-title {
      min-height: 32px;
      padding: 0;
    }

    .ant-modal-body {
      padding-top: 16px;
    }

    .ant-modal-footer {
      padding-top: 40px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-height: 379px;
    @include table-body-text-0;
  }

  &__flat {
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__flat-address {
    margin-bottom: 16px;
  }

  &__flat-inputs {
    display: flex;
    align-items: end;
    
    .input {
      margin-right: 16px;
      width: 320px;
    }

    .checkbox {
      margin: 0 0 16px 9px;
    }
  }

  &__flat-message {
    margin-top: 12px;
    color: $gray-medium;
    @include text-2;
  }
}
