.tabs-custom {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  &__close-icon {
    position: absolute;
    left: auto;
    right: 21px;
    top: 17px;
    height: 20px;
    width: 20px;

    svg {
      height: 20px;
      width: 20px;
      cursor: pointer;

      path {
        stroke: $gray-light-30;
      }

      &:hover {
        path {
          stroke: inherit;
        }
      }
    }
  }

  &__loader-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 300px;
  }

  &__tabs {
    height: 100%;

    &_hidden {
      display: none;
    }
  }

  .ant-tabs-nav-list {
    .ant-tabs-tab {
      width: 330px;
      padding: 16px;
      background: $white;
      border: 1px solid $gray-light-30;
      border-radius: 10px;
    }

    .ant-tabs-tab-btn {
      text-shadow: none;
      width: 100%;
      height: 100%;
    }

    .ant-tabs-tab-active {
      border-color: $blue;
      background: $blue-light;

      & .tabs-custom__tab__extra-content {
        background: $blue-light;
      }
    }

    .ant-tabs-tab-disabled {
      opacity: 0.5;
    }
  }

  .ant-tabs-nav {
    margin-bottom: 32px;
  }

  &__tab-container {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 19px 19px 19px;
    column-gap: 8px;
    row-gap: 16px;
  }

  &__tab__text {
    @include text-2;
    color: $gray-medium;
    white-space: nowrap;
  }

  &__tab__data-text {
    @include text-3;
    color: $black;
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
  }

  &__tab__extra-content {
    position: absolute;
    right: 4px;
    background-color: white;
  }

  .ant-tabs-nav {
    position: relative;
    overflow: hidden;
  }

  &__tabs-button {
    position: absolute;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $gray-light-30;
    background-color: $white;
    border-radius: 10px;
    cursor: pointer;
    z-index: 10;
    user-select: none;

    &_hide {
      display: none;
    }

    &_left {
      transform: translate(1px, -50%);
    }

    &_right {
      transform: translate(-100%, -50%);
    }
  }
}
