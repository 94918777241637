.button {
  height: auto;
  display: flex;
  align-items: center;
  border-radius: 8px;

  &__loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
  }

  &__icon {
    width: 16px;
    height: 16px;

    svg {
      width: 100%;
      height: 100%;
    }

    &_left {
      margin-right: 8px;
    }

    &_right {
      margin-left: 8px;
    }
  }

  &__primary {
    background: $blue;
    backdrop-filter: blur(4px);
    color: $white;

    &:hover {
      border-color: $blue-dark;
      background: $blue-dark;
      color: $white;
    }

    &:focus {
      border-color: $blue;
      background: $blue;
      color: $white;

      &:hover {
        border-color: $blue-dark;
        background: $blue-dark;
        color: $white;
      }
    }

    &:disabled {
      background: $blue;
      border-color: $blue-dark;
      opacity: 0.5;
      color: $white;

      &:hover {
        background: $blue;
        border-color: $blue-dark;
        opacity: 0.5;
        color: $white;
      }
    }
  }

  &__secondary {
    background: $white;
    backdrop-filter: blur(2px);
    border: 1px solid $gray-400;
    color: $black;

    &:hover {
      border-color: $gray-medium;
      background: $white;
      color: $black;
    }

    &:focus {
      border-color: $white;
      background: $white;
      color: $black;

      &:hover {
        border-color: $gray-medium;
        background: $white;
        color: $black;
      }
    }

    &:disabled {
      background: $white;
      border-color: $gray-400;
      opacity: 0.5;
      color: $black;

      &:hover {
        background: $white;
        border-color: $gray-400;
        opacity: 0.5;
        color: $black;
      }
    }
  }

  &__outline {
    background: $white;
    backdrop-filter: blur(2px);
    border: 1px solid rgba($blue, 0.5);
    color: $blue;

    &:hover {
      border-color: $blue;
      background: $white;
      color: $blue;
    }

    &:focus {
      background: $white;
      color: $blue;

      &:hover {
        border-color: $blue;
        background: $white;
        color: $blue;
      }
    }

    &:disabled {
      background: $white;
      border-color: $blue;
      opacity: 0.5;
      color: $blue;

      &:hover {
        background: $white;
        border-color: $blue;
        opacity: 0.5;
        color: $blue;
      }
    }
  }

  &__tertiary {
    background: $gray-200;
    backdrop-filter: blur(2px);
    border: none;
    color: $red;

    &:hover {
      border: none;
      background: $gray-light-30;
      color: $red;
    }

    &:focus {
      border-color: $gray-200;
      background: $gray-200;
      color: $red;

      &:hover {
        border: none;
        background: $gray-light-30;
        color: $red;
      }
    }

    &:disabled {
      background: $gray-200;
      border: none;
      color: $red;
      opacity: 0.5;

      &:hover {
        background: $gray-200;
        border: none;
        color: $red;
        opacity: 0.5;
      }
    }
  }

  &__size-small {
    padding: 11px 14px;
    @include button-text-1;
  }

  &__size-medium {
    padding: 14.5px 20px;
    @include button-text-0;
  }

  &__icon-size-small {
    width: 16px;
    height: 16px;
  }

  &__icon-size-medium {
    width: 19px;
    height: 19px;
  }
}
