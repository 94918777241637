.selectable-list {
  width: 100%;
  overflow: hidden;

  &__item {
    padding: 16px 18px 16px 16px;
    cursor: pointer;
    background: $white;

    &_bordered {
      border: 1px solid $gray-light-30 !important;
      border-radius: 8px;
      margin-top: 16px;

      &:first-child {
        margin-top: 0;
      }
    }

    &_selected {
      background: $blue-light;
      border: 1px solid $blue !important;
    }
  }
}
