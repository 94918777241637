.roles-customization-table {
  &__inputs {
    display: flex;
    max-width: 800px;
    margin-bottom: 32px;
    width: 100%;

    .select {
      margin-right: 16px;
      width: 100%;
    }
    .input {
      width: 100%;
    }
  }

  .ant-table-thead > tr > th {
    padding: 31px 12px;
    @include text-0;
    font-weight: 500;
    border-right: 1px solid $gray-light-20;
    background-color: $gray-light-10;
    text-align: center;

    &:first-child {
      background-color: $white;
      text-align: left;
    }
  }

  .ant-table-tbody > tr > td {
    @include text-0;
    background-color: $white;

    .editable-table__data-container {
      padding: 20px 12px;
      min-height: auto;
      justify-content: center;
    }

    &:first-child {
      .editable-table__data-container {
        justify-content: flex-start;
      }
    }
  }

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    border-right: 1px solid $gray-light-20;
  }
}
