.setting-section {
  &__group {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__group-title {
    @include text-4;
    color: $black;
    margin-bottom: 24px;

    &_disabled {
      opacity: 0.5;
    }
  }

  &__group-items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    row-gap: 24px;
    column-gap: 24px;
  }

  &__group-cell {
    display: flex;
    align-items: flex-end;
    position: relative;

    .input,
    .select {
      width: 100%;
    }

    .input .input__input {
      height: 48px;
    }

    .input.input-slider {
      margin-top: auto;
      padding-right: 15px;
    }

    .ant-slider.input__input {
      height: auto;
    }

    &_third-thirds {
      grid-column-start: 1;
      grid-column-end: 4;
    }

    &_two-thirds {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
}
