.systems-table {
  .ant-table-tbody > tr {
    > td {
      height: 100%;
      vertical-align: top;
      cursor: pointer;
    }
  }

  &__icon {
    cursor: pointer;
    width: 24px;
    height: 24px;

    svg {
      width: 100%;
      height: 100%;

      path {
        stroke: $gray-medium;
      }
    }

    &:first-child {
      margin-right: 12px;
    }

    &:hover {
      svg {
        path {
          stroke: $blue;
        }
      }
    }
  }

  &__text-bold {
    @include table-header-text-0;
    font-weight: 500;
    color: $black;
  }
}
