.message {
  &__success {
    .ant-message-notice-content {
      padding: 14px 16px;
      background: rgba($black, 0.7);
      border-radius: 8px;

      .ant-message-custom-content {
        display: flex;
        flex-direction: column;
        @include table-body-text-0;
        color: $gray-light-20;
        font-feature-settings: normal;

        .anticon {
          display: none;
        }
      }
    }
  }

  &__error {
    .ant-message-notice-content {
      padding: 14px 16px;
      background: rgba(255, 0, 0, 0.5);
      border-radius: 8px;
      backdrop-filter: blur(2px);

      .ant-message-custom-content {
        display: flex;
        flex-direction: column;
        @include table-body-text-0;
        color: $white;
        font-feature-settings: normal;

        .anticon {
          display: none;
        }
      }
    }
  }
}

.ant-message {
  top: inherit;
  bottom: 72px;
  z-index: 999;
  left: 236px;
  right: 0;
  width: auto;
}
