.array-range-field {
  .array-field {
    &__items {
      flex-direction: row;
      justify-content: space-between;
    }

    &__item {
      max-width: 373px;

      .input {
        width: 120px;
        margin-right: 8px;

        &:last-child {
          margin-right: 24px;
        }
      }
    }
  }

  &__inputs {
    display: flex;
  }
}

@include breakpoint-up($grid-breakpoint-xxl) {
  .array-range-field {
    .array-field {
      &__items {
        justify-content: start;
      }
  
      &__item {
        margin-right: 24px;
      }
    }
  }
}
