.change-main-info {
  &__button-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__wrapper {
    .ant-modal-header {
      padding-bottom: 0;
    }

    .ant-modal-title {
      min-height: 32px;
      padding: 0;
    }

    .ant-modal-body {
      padding-top: 4px;
    }

    .ant-modal-footer {
      padding-top: 40px;
    }
  }

  &__title {
    @include text-0;
    margin-bottom: 16px;
    color: $gray-medium;
  }

  .input__input {
    padding: 12px 16px;
  }
}
