.device-event-table {
  height: 100%;

  .ant-table-tbody > tr {
    > td {
      height: 100%;
      vertical-align: top;
    }
  }

  &__text {
    @include table-body-text-0;
    color: $black;

    &_bold {
      font-weight: 500;
      font-size: $table-header-text-0-font-size;
      line-height: $table-header-text-0-line-height;
    }
  }
}
