.flat-base-tab {
  &__info {
    margin-bottom: 32px;
  }

  &__tariff {
    color: $blue;
    cursor: pointer;
  }

  .editable-table__data-container {
    max-height: 60px;
    min-height: 60px;
  }
}
