.alert-block {
  width: 100%;
  border-radius: 8px;

  &__alert {
    padding: 12px;
    border: none;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .ant-alert-content {
      @include error-text-0;
    }
  }

  .ant-alert-error {
    background: rgba($red, 0.1);

    .ant-alert-message {
      color: $red;
    }
  }

  .ant-alert-success {
    background: rgba($green, 0.1);

    .ant-alert-message {
      color: $green;
    }
  }

  .ant-alert-info {
    background: $blue-light;

    .ant-alert-message {
      color: $blue;
    }
  }

  .ant-alert-warning {
    background: rgba($yellow, 0.1);

    .ant-alert-message {
      color: $yellow;
    }
  }
}
