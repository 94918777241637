.tariff-item {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .loader {
    margin: auto;
  }

  &__maintenance {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $gray-light-30;
    padding-bottom: 32px;

    .checkbox, .tariff-item__maintenance-label, .ant-switch {
      position: relative;
      top: 14px;
    }

    &-content {
      display: flex;
      align-items: center;
      margin: auto;
    }

    &-label {
      @include text-1;
      margin-left: 12px;
    }

    .checkbox {
      margin-right: 32px;
      transform: translate(0, -4px);
    }

    .input {
      margin-bottom: auto;
      margin-right: auto;
    }
  }

  .tab-nav-buttons {
    .ant-btn {
      width: 212px;
      justify-content: center;
    }

    .button {
      &__tertiary {
        margin-left: 0;
        margin-right: auto;
      }

      &__primary {
        margin-left: auto;
      }
    }
  }
}
