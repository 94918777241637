.recipients-modal {
  &__button-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__wrapper {
    .ant-modal-header {
      padding-bottom: 0;
      border-radius: 20px;
    }

    .ant-modal-title {
      min-height: 32px;
      padding: 0;
    }

    .ant-modal-content {
      overflow: visible;
    }

    .ant-modal-body {
      padding-top: 16px;
    }

    .ant-modal-footer {
      padding-top: 40px;
    }

    .simple-tabs {
      margin-top: 8px;

      &__container {
        max-height: 220px;
        overflow: scroll;
      }
    }
  }

  &__select {
    display: flex;
    flex-direction: column;

    &-name {
      @include text-1;
    }

    &-email {
      @include text-0;
    }
  }

  &__info {
    @include text-0;
    color: $gray-medium;
    margin-top: 8px;
  }
}
