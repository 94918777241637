//SF Pro
@font-face {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 700;
  src: url(../../public/fonts/SFPro/SFProDisplay-Bold.ttf) format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 500;
  src: url(../../public/fonts/SFPro/SFProDisplay-Regular.ttf) format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: 'SF Pro Text';
  font-style: normal;
  font-weight: 400;
  src: url(../../public/fonts/SFPro/SFProText-Regular.ttf) format('truetype');
  font-display: fallback;
}

//HelveticaNeue
@font-face {
  font-family: 'Helvetica Neue';
  src: url('../../public/fonts/HelveticaNeue/HelveticaNeueBoldCondensed.eot');
  src: local('Helvetica Neue Condensed Bold'), local('HelveticaNeueBoldCondensed'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueBoldCondensed.eot?#iefix') format('embedded-opentype'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueBoldCondensed.woff2') format('woff2'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueBoldCondensed.woff') format('woff'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueBoldCondensed.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../../public/fonts/HelveticaNeue/HelveticaNeueBlackCondensed.eot');
  src: local('Helvetica Neue Condensed Black'), local('HelveticaNeueBlackCondensed'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueBlackCondensed.eot?#iefix') format('embedded-opentype'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueBlackCondensed.woff2') format('woff2'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueBlackCondensed.woff') format('woff'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueBlackCondensed.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-Bold.eot');
  src: local('HelveticaNeueCyr-Bold'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-Bold.woff2') format('woff2'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-Bold.woff') format('woff'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-Light.eot');
  src: local('HelveticaNeueCyr-Light'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-Light.eot?#iefix') format('embedded-opentype'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-Light.woff2') format('woff2'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-Light.woff') format('woff'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-Medium.eot');
  src: local('HelveticaNeueCyr-Medium'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-Medium.eot?#iefix') format('embedded-opentype'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-Medium.woff2') format('woff2'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-Medium.woff') format('woff'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-MediumItalic.eot');
  src: local('HelveticaNeueCyr-MediumItalic'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-MediumItalic.woff2') format('woff2'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-MediumItalic.woff') format('woff'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-LightItalic.eot');
  src: local('HelveticaNeueCyr-LightItalic'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-LightItalic.woff2') format('woff2'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-LightItalic.woff') format('woff'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-HeavyItalic.eot');
  src: local('HelveticaNeueCyr-HeavyItalic'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-HeavyItalic.eot?#iefix') format('embedded-opentype'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-HeavyItalic.woff2') format('woff2'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-HeavyItalic.woff') format('woff'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-HeavyItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-Black.eot');
  src: local('HelveticaNeueCyr-Black'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-Black.eot?#iefix') format('embedded-opentype'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-Black.woff2') format('woff2'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-Black.woff') format('woff'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-Heavy.eot');
  src: local('HelveticaNeueCyr-Heavy'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-Heavy.eot?#iefix') format('embedded-opentype'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-Heavy.woff2') format('woff2'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-Heavy.woff') format('woff'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-Italic.eot');
  src: local('HelveticaNeueCyr-Italic'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-Italic.eot?#iefix') format('embedded-opentype'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-Italic.woff2') format('woff2'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-Italic.woff') format('woff'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-Italic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-Roman.eot');
  src: local('HelveticaNeueCyr-Roman'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-Roman.eot?#iefix') format('embedded-opentype'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-Roman.woff2') format('woff2'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-Roman.woff') format('woff'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-Roman.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-BlackItalic.eot');
  src: local('HelveticaNeueCyr-BlackItalic'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-BlackItalic.woff2') format('woff2'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-BlackItalic.woff') format('woff'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-UltraLightItalic.eot');
  src: local('HelveticaNeueCyr-UltraLightItalic'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-UltraLightItalic.eot?#iefix') format('embedded-opentype'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-UltraLightItalic.woff2') format('woff2'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-UltraLightItalic.woff') format('woff'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-UltraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-BoldItalic.eot');
  src: local('HelveticaNeueCyr-BoldItalic'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-BoldItalic.woff2') format('woff2'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-BoldItalic.woff') format('woff'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-ThinItalic.eot');
  src: local('HelveticaNeueCyr-ThinItalic'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-ThinItalic.eot?#iefix') format('embedded-opentype'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-ThinItalic.woff2') format('woff2'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-ThinItalic.woff') format('woff'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-UltraLight.eot');
  src: local('HelveticaNeueCyr-UltraLight'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-UltraLight.eot?#iefix') format('embedded-opentype'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-UltraLight.woff2') format('woff2'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-UltraLight.woff') format('woff'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-UltraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-Thin.eot');
  src: local('HelveticaNeueCyr-Thin'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-Thin.eot?#iefix') format('embedded-opentype'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-Thin.woff2') format('woff2'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-Thin.woff') format('woff'),
  url('../../public/fonts/HelveticaNeue/HelveticaNeueCyr-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

