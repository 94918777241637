.radio-group {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__title-container {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
  }

  &__title {
    @include input-title-0;
    color: $gray-medium;
  }

  &__title-info-icon {
    width: 16px;
    height: 16px;
    margin-left: 8px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__title-required {
    color: $red;
  }

  &__item {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
}
