@mixin button-schema-shadow() {
  @include shadow(1px, 1px, 7px, 2px, 0.15);
}

@mixin shadow($horizontal, $vertical, $blur, $stretch, $opacity) {
  -webkit-box-shadow: $horizontal $vertical $blur $stretch rgba(34, 60, 80, $opacity);
  -moz-box-shadow: $horizontal $vertical $blur $stretch rgba(34, 60, 80, $opacity);
  box-shadow: $horizontal $vertical $blur $stretch rgba(34, 60, 80, $opacity);
}

@mixin breakpoint-up($bp) {
  @media (min-width: $bp) {
      @content;
  }
}

@mixin breakpoint-down($bp) {
  @media (max-width: $bp) {
      @content;
  }
}

@mixin breakpoint-up-down($bp1, $bp2) {
  @media (min-width: $bp1) and (max-width: $bp2) {
      @content;
  }
}