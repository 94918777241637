.tariff-cost {
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid $gray-300;

  &__title {
    @include help-header-0;
    margin-bottom: 24px;
  }

  &__tariffs {
    width: 100%;
  }

  &__tariffs-item {
    margin-bottom: 24px;

    &-title {
      @include text-4;
      margin-bottom: 16px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__tariffs-inputs {
    display: flex;
    align-items: end;

    .input, .number-input {
      width: 100%;
    }

    &-sign {
      color: $gray-500;
      font-size: 28px;
      margin: 0 16px;
      font-weight: 300;
      transform: translate(0, -6px);
    }
  }
}
