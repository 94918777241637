.schema-context-menu {
  .ant-dropdown-menu {
    border-radius: 8px;
    max-width: 168px;
    padding: 8px 0;

    &-item {
      padding: 8px 16px;
      cursor: pointer;
      @include text-0;
      font-size: 12px;

      svg {
        max-height: 16px;
        max-width: 16px;
        width: 100%;
        height: 100%;
        margin-right: 4px;
        margin-bottom: auto;

        path {
          stroke: $black;
        }
      }

      &:hover {
        color: $blue;

        svg {
          path {
            stroke: $blue;
          }
        }
      }
    }
  }
}
