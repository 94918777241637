.input-slider {
  &.input {
    overflow: visible;
  }

  .input__title-container {
    margin-bottom: 10px;
  }

  .input__input {
    padding: 0;
    border: none;
    margin: 0 0 0 7px;

    .ant-slider-dot {
      transform: translate(-1px, 11px) !important;
      width: 1px;
      height: 4px;
      background-color: $gray-light-20;
      border-radius: 0%;
      border: none;
    }

    .ant-slider-mark-text {
      @include text-0;
      font-size: 12px;
      color: $gray-medium;
    }
  }
}
