.role-base-tab {
  &__about {
    display: flex;
    padding: 12px 16px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    border: 1px solid $gray-light-30;
    margin-bottom: 32px;
    max-width: 800px;

    &-item {
      display: flex;
      margin-bottom: 18px;

      &:last-child {
        margin-bottom: 0
      }

      &-value {
        @include text-1;
      }

      &-label {
        width: 140px;
        @include text-0;
        color: $gray-medium;
      }
    }
  }
}
