.checkbox {
  display: flex;
  align-items: center;

  &:after {
    content: none;
  }

  > span {
    @include text-0;
    font-size: $tab-title-0-font-size;
    line-height: $tab-title-0-line-height;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: $black;
  }

  &__label-disable {
    span {
      &:nth-child(2) {
        padding: 0;
      }
    }
  }

  .ant-checkbox, .ant-checkbox-inner {
    width: 18px;
    height: 18px;
    top: 0;

    &::after {
      width: 8px;
      height: 12px;
    }
  }

  .ant-checkbox-inner {
    &::after {
      left: 3px;
    }
  }

  &:hover {
    .ant-checkbox-checked {
      &:after {
        width: 100%;
        height: 100%;
      }
    }
  }

  .ant-checkbox-disabled {
    opacity: 0.5;

    .ant-checkbox-inner {
      background-color: $gray-light-10;
      border-color: $gray-light-30 !important;
    }
  }

  &__checked {
    .ant-checkbox-disabled {
      opacity: 0.5;

      .ant-checkbox-inner {
        background-color: $blue;
        border-color: $blue !important;
      }
    }

    &_disabled {
      .ant-checkbox-disabled {
        .ant-checkbox-inner {
          background-color: $gray-medium;
          border-color: $gray-medium !important;
        }
      }
    }
  }

  &__error {
    .ant-checkbox {
      .ant-checkbox-inner {
        border-color: $red;
      }
    }
  }
}
