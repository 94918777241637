@mixin transition($property, $value, $type) {
  -moz-transition: $property $value $type;
  -o-transition: $property $value $type;
  -webkit-transition: $property $value $type;
}

@mixin transition-opacity-standard() {
  @include transition(opacity, 0.25s, ease-in-out)
}

@mixin transition-standard() {
  @include transition(all, 0.5s, ease-out)
}

@mixin transform($property) {
  -webkit-transform: scale($property);
  -ms-transform: scale($property);
  transform: scale($property);
}

@mixin base-transform() {
  @include transform(1.01)
}
