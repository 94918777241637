.table {
  &__icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    width: 100%;
    height: 100%;
  }

  &__icon-cell {
    vertical-align: middle !important;
  }

  .ant-table {
    border: 1px solid $gray-light-40;
    border-radius: 8px;
    overflow: hidden;
  }

  .ant-table-thead > tr > th {
    background: none;
    border-bottom: 1px solid $gray-light-20;
    @include table-header-text-0;
    font-weight: 600;
    color: #000000;
    padding: 20px;

    &:before {
      display: none;
    }
  }

  .ant-table-tbody > tr {
    > td {
      background: none;
      border-bottom: 1px solid $gray-light-20;
      @include table-body-text-0;
      color: #000000;
      padding: 20px;
      vertical-align: middle;
    }

    &:last-child > td {
      border-bottom: none;
    }
  }

  .ant-table-cell-row-hover {
    background: $gray-light-10 !important;

    .table__icon-container {
      visibility: visible;
    }
  }

  .ant-table-cell:empty:before {
    content: '-';
  }

  .ant-spin-nested-loading  {
    position: static;

    & > div:first-child {
      position: relative;
      width: 100%;
    }

    .ant-spin {
      height: auto;
      top: 100px;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .ant-spin-dot {
        top: 100%;
        left: auto;
        right: auto;
        margin: 0;
      }
    }

    .ant-spin-container {
      position: static !important;
    }
  }

  &__pagination {
    margin: 20px 0 0 0 !important;

    .ant-pagination-prev,
    .ant-pagination-item,
    .ant-pagination-next,
    .ant-pagination-jump-next,
    .ant-pagination-jump-prev {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include table-body-text-0;
      border: 1px solid $gray-light-30;
      border-radius: 8px;
      color: $blue;

      &:hover {
        color: $blue;
        border-color: $blue;
      }

      &:disabled {
        border-color: $gray-light-30;
      }

      a {
        padding: 0;
        color: inherit;
        outline: none;
        text-decoration: none;
      }

      .ant-pagination-item-link, .ant-pagination-item-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .ant-pagination-item-ellipsis {
          @include table-body-text-0;
          color: $blue;
          top: 50%;
        }
      }
    }

    .ant-pagination-item-active {
      background: $blue;
      color: $white;
      border-color: $blue;

      &:hover {
        background: $blue;
        color: $white;
        border-color: $blue;
      }
    }

    &_absolute {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 4px;
      padding: 16px 20px 16px 24px;
      display: flex;
      align-items: center;
      background: $white;
      z-index: 2;
    }
  }

  &__empty-body-transparent {
    .ant-table {
      border: none;
    }

    .ant-table-thead > tr > th {
      border-top: 1px solid $gray-light-40;
      border-bottom: 1px solid $gray-light-40;

      &:first-child {
        border-bottom-left-radius: 8px;
        border-top-left-radius: 8px;
        border-left: 1px solid $gray-light-40;
      }

      &:last-child {
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;
        border-right: 1px solid $gray-light-40;
      }
    }

    .ant-table-container table > thead > tr:first-child th:first-child {
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
    }

    .ant-table-container table > thead > tr:first-child th:last-child {
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
    }
  }
}
