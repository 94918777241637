.flat-keys-tab {
  &__subtitle {
    @include text-0;
    margin-bottom: 3px;
    color: $gray-medium;
  }

  &__form {
    display: flex;
    margin-bottom: 32px;

    .tab-nav-buttons {
      .ant-btn {
        margin-left: auto;
      }
    }

    & > div {
      min-width: 33%;
      margin-right: 24px;
    }
  }

  .error-placeholder__text {
    width: 448px;
  }

  &__tree-info {
    @include text-1;
    margin-bottom: 16px;
    color: $gray-medium;

    &-link {
      color: $blue;
      cursor: pointer;
      margin-left: 3px;
    }
  }
}
