.equipment-data {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__sync-notify {
    @include text-1;
    color: $gray-medium;
    margin-bottom: 32px;

    &-link {
      color: $blue;
      cursor: pointer;
    }
  }

  &__tools-container {
    width: 100%;
    display: flex;
    align-items: baseline;
  }

  &__select {
    min-width: 330px;
    width: auto;
    margin-right: 24px;
  }

  &__content-wrapper {
    margin-top: 32px;
    width: 100%;
    height: 100%;
  }

  .tabs-custom__loader-wrapper {
    top: auto;
  }
}
