.feedback-table-page {
  &__tools {
    .input-search {
      flex: 3;
    }
  }

  &__content {
    height: 100%;
    display: flex;
  }
}
