.status-pop-up {
  position: absolute;
  z-index: 4;

  &__background {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 3;
  }

  &__item {
    width: 388px;
    background-color: $gray-light-50;
    padding: 16px 16px 16px 48px;
    border-radius: 20px;
    margin-bottom: 12px;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    &-title {
      @include text-0;
      font-weight: 500;
      margin-bottom: 6px;
    }

    &-text {
      @include text-0;
    }

    .dispatcher-panel__status {
      &::before {
        position: absolute;
        transform: translate(-27px, 1px);
        width: 16px;
        height: 16px;
      }
    }
  }
}
