.schema-canvas {
  width: 100%;
  height: 100%;
  position: relative;

  &__buttons-container {
    position: absolute;
    left: auto;
    right: 8px;
    top: 8px;
  }

  &__button {
    cursor: pointer;
    @include transition(all, 0.3s, ease-out);
    border-radius: 50%;
    height: 32px;
    width: 32px;

    &:hover {
      @include transform(1.2);
      @include button-schema-shadow();
    }
  }

  &__zoom {
    position: absolute;
    left: auto;
    top: auto;
    bottom: 5px;
    right: 5px;
    color: $gray-medium;
    @include text-0;
    cursor: default;
  }

  &__position {
    position: absolute;
    left: 5px;
    top: auto;
    bottom: 5px;
    color: $gray-medium;
    @include text-0;
    display: flex;
    flex-direction: column;
    cursor: default;
  }

  &__canvas {
    width: 100%;
    height: 100%;
    border: 1px solid rgba($black, 0.1);
    border-radius: 8px;

    canvas[resize] {
      width: 100%;
      height: 100%;
    }
  }
}
