.notifications-block {
  margin-right: 8px;
  padding: 10px 12px;
  border-radius: 20px;
  height: 40px;

  &.button {
    span {
      @include text-1;
      color: $blue;
      transform: translate(0, 1px);
    }
  }

  .button__icon {
    &_left {
      width: 24px;
      height: 24px;
    }

    &_right {
      display: flex;
      align-items: center;
      margin-left: 8px;
      @include transition-standard();
      height: 24px;
      width: 24px;
      transform: translate(0px, -1px);

      svg {
        path {
          stroke: $blue;
        }
      }
    }
  }

  &_disabled {
    border: 1px solid $gray-medium !important;

    &.button {
      span {
        color: $gray-medium;
      }

      &:hover {
        border: 1px solid $gray-medium;
      }
    }

    .button__icon_right {
      svg {
        path {
          stroke: $gray-medium;
        }
      }
    }
  }

  &_open {
    .button__icon_right {
      transform: rotate(180deg);
    }
  }
}

.notifications-volume {
  height: 40px;
  margin-right: 8px;
  cursor: pointer;
}
