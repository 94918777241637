.recipients-table {
  &__icon-hide {
    opacity: 0;
    pointer-events: none;
  }

  &.table .ant-table-tbody > tr > td {
    vertical-align: baseline;
  }
}
