.service-codes-tab {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 16px;

  &__button-next {
    margin-left: auto;
  }

  &__extra-icon {
    cursor: pointer;

    svg {
      height: 16px;
      width: 16px;
    }

    &:hover {
      svg {
        path {
          stroke: $blue;
        }
      }
    }
  }

  .tabs-custom__tab-container {
    grid-template-rows: auto;
  }
}
