.roles-table {
  &__message-disabled {
    @include text-1;
    color: $gray-medium;
  }

  &.editable-table {
    .ant-table-cell {
      cursor: pointer;
      .editable-table__data-container,
      .editable-table__icon-container {
        padding: 0 12px;
        min-height: 56px;
        hyphens: auto;
      }

      .editable-table__icon-container {
        opacity: 0;
      }
    }

    .ant-table-thead > tr > th {
      padding: 17px 12px;
    }

    .ant-table-row {
      &:hover {
        .editable-table__icon-container {
          opacity: 1;
        }
      }
    }
  }
}
