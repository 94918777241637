.tracts-modal {
  &__wrapper {
    .ant-modal-content {
      overflow: visible;
    }

    .ant-modal-header {
      padding-bottom: 16px;
      border-radius: 20px;
    }

    .ant-modal-title {
      font-size: 24px;
    }
  }

  &__content {
    max-height: calc(100vh - 250px);
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    padding-right: 10px;

    & > div {
      width: 100%;

      &:first-child {
        margin-right: 40px;
      }
    }
  }

  &__wrapper {
    .ant-modal-body {
      height: calc(100vh - 230px);
    }
  }

  &__title {
    display: flex;
    margin-bottom: 24px;
    @include text-1;
    font-weight: 500;

    & > div {
      width: 100%;

      &:first-child {
        margin-right: 40px;
      }
    }
  }
}
