// block sizes
$sidebar-width: 118px;
$sidebar-expanded-width: 236px;
$tree-width: 330px;
$schema-buttons-height: 106px;
$layout-vertical-padding: 24px;

//fonts
$sf-pro-display: SF Pro Display, Arial, sans-serif;
$sf-pro-text: SF Pro Text, Arial, sans-serid;
$helvetica-neue: Helvetica Neue, Arial, sans-serif;

$heading-0-font-size: 32px;
$heading-0-line-height: 39px;

$heading-1-font-size: 20px;
$heading-1-line-height: 24px;

$heading-2-font-size: 16px;
$heading-2-line-height: 19px;

$heading-3-font-size: 14px;
$heading-3-line-height: 17px;

$heading-4-font-size: 16px;
$heading-4-line-height: 20px;

$text-0-font-size: 14px;
$text-0-line-height: 130%;

$text-1-font-size: 16px;
$text-1-line-height: 20px;

$text-2-font-size: 14px;
$text-2-line-height: 16px;

$text-3-font-size: 14px;
$text-3-line-height: 19px;

$text-4-font-size: 18px;
$text-4-line-height: 22px;

$error-text-0-font-size: 12px;
$error-text-0-line-height: 14px;

$tab-title-0-font-size: 16px;
$tab-title-0-line-height: 20px;

$input-title-0-font-size: 14px;
$input-title-0-line-height: 16px;

$input-error-text-0-font-size: 12px;
$input-error-text-0-line-height: 14px;

$input-text-0-font-size: 16px;
$input-text-0-line-height: 19px;

$button-text-0-font-size: 16px;
$button-text-0-line-height: 19px;

$button-text-1-font-size: 14px;
$button-text-1-line-height: 16px;

$link-button-text-0-font-size: 16px;
$link-button-text-0-line-height: 18px;

$tooltip-text-0-font-size: 12px;
$tooltip-text-0-line-height: 14px;

$table-header-text-0-font-size: 16px;
$table-header-text-0-line-height: 20px;

$table-body-text-0-font-size: 16px;
$table-body-text-0-line-height: 19px;

$help-header-0-font-size: 24px;
$help-header-0-line-height: 29px;

$help-title-0-font-size: 18px;
$help-title-0-line-height: 22px;

//colors
$blue: #1444F2;
$blue-dark: #0A2EB4;
$blue-light: #EFF3FF;
$blue-disabled: #8aa1f9;
$red: #F00000;
$red-light: #FFF2F2;
$green: #47C80A;
$yellow: #FFB800;
$orange: #F06500;
$black: #000000;
$gray-1000: #1D222D;
$gray-900: #666666;
$gray-800: #808080;
$gray-700: #9B9B9B;
$gray-600: #B3B3B3;
$gray-500: #CCCCCC;
$gray-400: #E8E8E8;
$gray-300: #EDEDED;
$gray-200: #F2F2F2;
$gray-100: #F5F5F5;
$gray-50: #F7F7F7;
$gray-light-0: #E0E0E0;
$gray-light-10: #FAFAFA;
$gray-light-20: #E4E4E4;
$gray-light-30: #E6E6E6;
$gray-light-40: #E9E9E9;
$gray-light-50: #F4F4F4;
$gray-medium: #949494;
$white: #FFFFFF;

$grid-breakpoint-xs: 320px;
$grid-breakpoint-sm: 576px;
$grid-breakpoint-md: 768px;
$grid-breakpoint-lg: 1280px;
$grid-breakpoint-xl: 1366px;
$grid-breakpoint-xxl: 1400px;
$grid-breakpoint-xxxl: 1600px;
