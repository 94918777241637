.dispatcher-panel-table {
  width: 100%;

  &__device-name {
    min-height: 56px;
    display: flex;
    align-items: center;
    padding: 0 0 0 30px;

    .tooltip__wrapper {
      margin: 2px 0 0 auto;
    }

    &-label {
      position: relative;
      margin-right: 8px;

      &::before {
        position: absolute;
        transform: translate(-16px, 4px);
      }
    }
  }

  &.editable-table {
    .ant-table-cell {
      cursor: pointer;
      .editable-table__data-container,
      .editable-table__icon-container {
        padding: 0 12px;
        min-height: 56px;
        hyphens: auto;
      }
    }

    .ant-table-thead > tr > th {
      padding: 17px 12px;
    }
  }

  &__notify {
    opacity: 0;

    &_active {
      opacity: 1;
    }

    &_error {
      opacity: 1;

      svg {
        path {
          fill: $red;
        }
      }
    }

    &:hover {
      svg {
        path {
          stroke: inherit;
        }
      }
    }
  }

  &_absolute {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 23px;
    background-color: white;
    padding: 16px 0;
  }
}
