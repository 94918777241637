.disabling-push-table {
  width: 100%;

  &__line {
    height: 1px;
    width: 100%;
    background-color: $gray-light-30;
    margin: 24px 0;
  }

  &.editable-table {
    .ant-table-cell {
      .editable-table__data-container,
      .editable-table__icon-container {
        padding: 10px 14px;
        min-height: 56px;
        hyphens: auto;
      }

      .editable-table__icon-container {
        svg {
          &:hover {
            path {
              stroke: $red;
            }
          }
        }
      }
    }

    .ant-table-thead > tr > th {
      padding: 16px 12px;
      font-weight: 500;
    }
  }
}
