.input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;

  &__title-container {
    display: flex;
    @include input-title-0;
    color: $gray-medium;
    align-items: center;
    margin-bottom: 6px;
  }

  .ant-input-affix-wrapper > .ant-input:not(textarea) {
    border: none;
  }

  &__title {
    @include input-title-0;
    color: $gray-medium;
  }

  &__title-info-icon {
    width: 16px;
    height: 16px;
    margin-left: 8px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__title-required {
    color: $red;
  }

  &__error {
    @include input-error-text-0;
    color: $red;
    margin-top: 6px;
  }

  .ant-input-password {
    height: 48px;
  }

  &__input,
  &__input .ant-input {
    @include input-text-0;
    color: $black;
    background: $white;
    border: 1px solid $gray-light-30;
    border-radius: 8px;
    padding: 13.5px 16px;
    resize: none;
    width: 100%;

    &::placeholder {
      color: $gray-500;
    }

    &:disabled {
      color: $black;
      background: $white;
      cursor: auto;
      border: 1px solid $gray-light-30;
    }

    &:hover {
      border-color: $gray-light-30;
    }

    &:focus,
    &:focus-visible {
      box-shadow: none !important;
      border-color: $blue;
      outline: none;
    }

    &_default-disabled {
      &:disabled {
        color: $gray-medium;
        background: $gray-light-10;
        cursor: auto;
        border: 1px solid $gray-light-30;
      }
    }
  }

  &-description {
    @include text-0;
    margin-top: 5px;
    font-size: 12px;
    line-height: 14px;
    color: $gray-500;
  }

  &__mask-error {
    border-color: $red;

    &:hover {
      border-color: $red;
    }
  }

  .ant-input-status-error {
    background: $red-light !important;
  }

  .ant-input-affix-wrapper-focused {
    box-shadow: none;
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type='number'],
  input[type='number']:hover,
  input[type='number']:focus {
    appearance: none;
    -moz-appearance: textfield;
  }
}
