.subscribers-new {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__button-wrapper {
    margin-bottom: 32px;
  }

  &__content {
    width: 100%;
    height: 100%;
  }

  &__tabs {
    .ant-tabs-nav {
      margin-bottom: 32px;
    }
  }
}
