.device-sync-modal {
  .confirm-modal__text {
    margin-top: 16px;
    @include text-0;
    color: $gray-medium;
  }

  &__final {
    display: flex;

    svg {
      margin-right: 8px;
      min-width: 16px;
      min-height: 16px;
    }
  }

  &__wait {
    display: flex;

    .loader {
      height: 16px;
      width: 16px;
      margin-right: 8px;

      &__content {
        height: 16px;
        width: 16px;
      }
    }
  }
}
