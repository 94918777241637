.feedback-page {
  &__tabs {
    .ant-tabs-nav-list {
      background-color: $gray-light-10;
    }
  }

  &__button-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;

    .feedback-page__status-btn {
      height: 36px;
      padding: 16px 10px;
      @include text-0;
      pointer-events: none;

      &_reopened {
        color: $orange;
        border: 1px solid rgba($orange, 0.5);
      }

      &_initial {
        color: $green;
        border: 1px solid $green;
      }
    }
  }

  &__loader-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__user {
    color: blue;
    cursor: pointer;
  }

  &__content {
    display: flex;

    &-main {
      min-width: 58%;
      margin-right: 24px;
    }

    &-additional {
      min-width: 40%;
    }
  }

  &__priority {
    height: 20px;
    transform: translate(-5px, 0);
  }

  &__status,
  .feedback-status {
    @include text-0;
    padding: 4px 12px;
  }

  &__info {
    margin-bottom: 32px;

    .information-block__section-item {
      &-name {
        min-width: 110px;
        width: 100%;
        max-width: 130px;
      }

      &-value {
        display: flex;
      }
    }
  }

  &__main-info {
    margin-bottom: 32px;
  }

  &__title {
    @include help-header-0;
    margin-bottom: 16px;
  }

  &__text {
    @include text-1;
    margin-bottom: 16px;
  }
}
