.users {
  &__create {
    display: flex;
    flex-direction: column;
    margin-left: 24px;

    &-count {
      margin-top: 8px;
      @include text-1;
      color: $gray-medium;
      text-align: end;
    }
  }
}
