.array-field {
  width: 100%;

  &__content {
    padding: 16px;
    background: $gray-light-10;
    border: 1px solid $gray-light-30;
    border-radius: 8px;
  }

  &__plus {
    margin-top: 10px;

    svg {
      cursor: pointer;
      path {
        stroke: $blue;
      }
    }

    &_disabled {
      svg {
        cursor: auto;
        path {
          stroke: $gray-medium;
        }
      }
    }
  }

  &__content_error {
    border: 1px solid $red;
  }

  &__title {
    @include text-1;
    color: $black;

    &_disabled {
      color: $gray-medium;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  &__input-disabled {
    .input__input_default-disabled {
      background: $white;
    }
  }

  &__item {
    display: flex;
    align-items: flex-start;
    margin-top: 24px;

    .input {
      width: 100%;
      max-width: 100%;
      margin-right: 24px;
    }
  }

  &__item-icons {
    display: flex;
    flex-direction: row;
    margin-right: auto;
  }

  &__item-icon {
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $blue;
    border-radius: 8px;
    margin-right: 8px;
    cursor: pointer;

    svg {
      path {
        stroke: $blue;
      }
    }

    &_disabled {
      border: 1px solid $gray-medium;
      cursor: default;

      svg {
        path {
          stroke: $gray-medium;
        }
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
