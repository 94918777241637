.button-link {
  display: flex;
  align-items: center;
  cursor: pointer;

  &__icon {
    width: 24px;
    height: 24px;

    svg {
      width: 100%;
      height: 100%;
      fill: $black;
    }
  }

  &__content {
    margin: 0 8px;
    @include link-button-text-0;
    color: $black;
  }
}
