.access-groups-tab {
  padding-top: 16px;

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__filters {
    display: flex;
    margin-bottom: 20px;

    .checkbox {
      min-width: 180px;
      margin-right: 32px;
    }
  }

  &__notification {
    @include text-1;
    color: $gray-medium;
    margin-bottom: 16px;
  }

  &__content-wrapper {
    width: 100%;
    height: calc(100vh - 370px);
    display: grid;
    grid-template-columns: $tree-width calc(100% - #{$tree-width} - 10px);
    grid-template-rows: 100%;
    column-gap: 10px;
    overflow: hidden;
    background-color: $white;
  }

  &__tree {
    width: 100%;
    height: 100%;
  }

  &__content {
    width: 100%;

    .section-flats-tools {
      margin-bottom: 8px;
    }
  }

  &__table {
    height: calc(100% - 56px);
  }

  &__button-next {
    margin-left: auto;
  }
}
