.transfer-object {
  &__button-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__wrapper {
    .ant-modal-header {
      padding-bottom: 0;
    }

    .ant-modal-title {
      min-height: 32px;
      padding: 0;
    }

    .ant-modal-body {
      .input {
        margin-bottom: 24px;
      }
    }

    .ant-modal-footer {
      padding-top: 16px;
    }
  }

  &__text {
    margin-bottom: 24px;
    @include text-1;
    color: $gray-medium;
  }
}
