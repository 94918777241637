.faq-chapter {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__title {
    @include help-header-0;
    color: $black;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__question {
    width: 100%;
    padding-top: 16px;

    &:first-child {
      padding-top: 24px;
    }
  }
}
