.layout {
  min-height: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  background: $white;

  &__sidebar {
    z-index: 1;
  }

  &__header {
    height: auto;
    padding: 0;
    color: $black;
    line-height: normal;
    background: $white;
    z-index: 2;
  }

  &__content-wrapper {
    position: relative;
    width: calc(100% - #{$sidebar-width});
    height: 100%;
    display: flex;
    flex-direction: column;
    @include transition-standard;

    &_sidebar-expanded {
      width: calc(100% - #{$sidebar-expanded-width});
    }
  }

  &__content {
    position: relative;
    width: 100%;
    max-width: 100%;
    padding: 32px $layout-vertical-padding 82px;
    min-height: 100%;
    z-index: 0;
  }

  &__scrollbar {
    z-index: 0;

    &_full-screen {
      z-index: 3;
    }
  }

  &__content-container {
    width: 100%;
    min-height: 100%;
  }
}
