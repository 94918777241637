.instruction-question {
  width: 100%;
  height: 100%;
  border: 1px solid $gray-light-30;
  background: $white;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &__ellipsis-2-line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__header {
    width: 100%;
    padding: 16px;
    min-height: 76px;
    display: flex;
    align-items: center;
    background: $gray-light-50;
  }

  &__title {
    width: 100%;
    @include help-title-0;
    color: $black;
  }

  &__body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;
  }

  &__text {
    @include input-text-0;
    color: $black;
    text-decoration: none;
    outline: none;

    &:hover {
      color: $black;
    }
  }

  &__date {
    @include input-text-0;
    color: $black;
    opacity: 0.5;
    align-self: center;
  }

  &__link {
    @include input-text-0;
    color: $blue;
    text-decoration: none;
    outline: none;

    &:hover {
      color: $blue;
    }
  }

  &__toolbox {
    width: 100%;
    height: 38px;
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__toolbox-items {
    height: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: 1fr;
    column-gap: 24px;
  }
}
