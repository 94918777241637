.feedback-status {
  @include text-1;
  padding: 8px 16px;
  border-radius: 8px;
  text-wrap: nowrap;

  &_green {
    background-color: rgba($green, 0.2);
  }
  &_red {
    background-color: rgba($red, 0.2);
  }
  &_blue {
    background-color: rgba($blue-light, 0.8);
  }
  &_gray {
    background-color: $gray-light-20;
  }
  &_orange {
    background-color: rgba($orange, 0.2);
  }
}
