.warning-block {
  display: flex;

  &__icon {
    height: 20px;
    width: 20px;
    margin-right: 4px;
  }

  &__messages {
    display: flex;
    flex-direction: column;
  }

  &__message {
    @include text-1;
    color: $gray-medium;
  }
}
