.system-section-structure-tab {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 16px;

  .ant-table {
    .single-date-picker {
      width: 100%;
      max-width: 156px;
      height: 32px;
    }

    .ant-table-container table > thead > tr:first-child th:last-child {
      padding: 0;
    }

    .editable-table__data-container {
      .single-date-picker__input-filed-icon_clear {
        height: 15px;
        top: 7px;
      }

      &_disabled {
        color: $gray-medium;
      }
    }

    .editable-table__title-icon {
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      transform: translate(-6px, 6px);
    }

    .single-date-picker__dropdown {
      z-index: 100;
    }

    .single-date-picker__input-field,
    .single-date-picker__field_clear {
      padding: 7px 32px 7px 8px;
      max-height: 32px;
    }
  }

  &__button-next {
    margin-left: auto;
  }
}
