.select-multiple {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  &__title {
    @include input-title-0;
    color: $gray-medium;
    margin-bottom: 6px;
  }

  &__title-required {
    color: $red;
  }

  &__input-loader {
    width: 19px;
    height: 19px;
  }

  &__dropdown-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    max-height: 400px;
    border-radius: 8px;
  }

  &__loader-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;
  }

  &__option-item-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @include input-text-0;
    padding: 16px 24px 16px 16px;
    border-bottom: 1px solid $gray-light-30;
    cursor: pointer;

    &:last-child {
      border: none;
    }

    &:hover {
      background: $gray-light-10;
    }

    &_selected {
      font-weight: 600;
      background: $gray-light-10;
      color: $black;
    }
  }

  &__option-item-text {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    hyphens: auto;
  }

  &__option-checkbox {
    margin-left: 8px;
    .ant-checkbox {
      margin-left: auto;
    }
  }

  &__search-input {
    border: none;
    border-bottom: 1px solid $gray-light-30;
    border-radius: 0;

    &:hover {
      border-color: $gray-light-30;
    }

    &:focus {
      border-color: $gray-light-30;
    }
  }

  &__dropdown {
    padding: 0;
    border: 1px solid $gray-light-30;
    border-radius: 8px;
    box-shadow: none;
    filter: drop-shadow(0px 2px 4px rgba($black, 0.15));
  }

  &__select {
    width: 100%;

    .ant-select-selector {
      @include input-text-0;
      color: $black;
      background: $white;
      border: 1px solid $gray-light-30 !important;
      border-radius: 8px !important;
      padding: 13.5px 40px 13.5px 16px !important;
      min-height: 48px;
      box-shadow: none !important;
      height: auto !important;
      overflow: hidden;
      max-width: 100%;

      &:after {
        display: none;
      }

      .ant-select-selection-placeholder {
        text-overflow: ellipsis;
        max-width: 100%;
        padding: 0;
        left: 16px;
        right: 40px;
        min-height: 16px;
        line-height: normal;

        &:after {
          content: none !important;
        }
      }

      .ant-select-selection-overflow {
        flex-wrap: nowrap;
        flex: auto;
        align-items: center;
        overflow: hidden;
        height: auto;
        width: 100%;

        &:after {
          display: none;
        }

        .ant-select-selection-overflow-item {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          flex-wrap: nowrap;
          color: $black;

          &:after {
            content: ',';
            margin-right: 6px;
          }

          &:nth-last-child(2),
          &:last-child {
            &:after {
              display: none;
            }
          }
        }

        .ant-select-selection-overflow-item-suffix {
          display: none;
        }
      }
    }

    .ant-select-arrow {
      height: 100%;
      top: 0;
      bottom: 0;
      right: 16px;
      margin: 0;
      padding: 0;

      svg {
        transition: all 0.3s;
      }
    }
  }

  .ant-select-disabled {
    .ant-select-selector {
      color: $black !important;
      background: $white !important;
      border: 1px solid $gray-light-30 !important;
    }
  }

  .ant-select-open {
    .ant-select-arrow {
      svg {
        transform: rotate(-180deg);
      }
    }
  }

  &__icon-clear-wrapper {
    height: 30px;
    top: 31px;
    right: 38px;
  }

  &__clear-icon {
    .ant-select-selection-overflow {
      max-width: calc(100% - 22px);
    }
  }
}
