.journal-table {
  &__date {
    @include text-1;
    color: $gray-medium;
  }

  &__key {
    &-container {
      display: flex;
      flex-direction: column;
      align-items: baseline;
    }

    &-name {
      @include text-0;
      color: $gray-medium;
    }

    &-alias {
      @include text-1;
    }
  }
}
