.volume-settings-modal {
  &__button-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__slider {
    padding-right: 22px;
    margin-bottom: 30px;
  }

  &__wrapper {
    .ant-modal-header {
      padding-bottom: 0;
    }

    .ant-modal-title {
      min-height: 32px;
      padding: 0;
    }

    .ant-modal-body {
      padding-top: 16px;
    }

    .ant-modal-footer {
      padding-top: 40px;
    }
  }
}
