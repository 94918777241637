.main-options {
  &__select {
    width: 100%;
    margin-bottom: 32px;

    .select {
      max-width: calc(50% - 14px);
      width: 100%;
    }
  }

  &__radio-container {
    width: 100%;
    display: flex;
    align-items: center;

    &_mb {
      margin-bottom: 24px;
    }
  }

  &__certificate {
    display: flex;
    flex-direction: column;
    width: 100%;

    &-label {
      color: $gray-medium;
      white-space: nowrap;
    }

    &-content {
      display: flex;
      flex-direction: row;
      align-items: end;
    }

    &-icon {
      margin-right: 5px;
      position: relative;
      top: 2px;
    }

    &-status {
      @include text-1;
      white-space: nowrap;
    }

    &-create {
      @include text-1;
      color: $blue;
      margin-left: 16px;
      cursor: pointer;
    }

    &-download {
      display: flex;
      align-items: end;
      margin-left: 16px;
      @include text-1;
      color: $blue;
      cursor: pointer;

      &-icon {
        margin-left: 7px;
        transform: scale(1.3);
        position: relative;
        top: 2px;
      }
    }
  }

  &__revision {
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;

    & > div {
      width: 100%;
      max-width: 50%;
      margin-right: 24px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    background-color: $gray-light-10;
    border: 1px solid $gray-light-30;
    border-radius: 8px;
    padding: 16px;

    &_last-element {
      margin-bottom: 32px;
    }
  }

  &__options {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    column-gap: 24px;
    padding-bottom: 24px;

    &_alt {
      grid-template-columns: repeat(3, 1fr);
    }

    &_gap {
      row-gap: 24px;
    }

    > div {
      min-width: 100%;
    }

    .ant-btn {
      max-height: 48px;
      margin-top: auto;
      min-width: auto;
      width: fit-content;
    }
  }

  &__options-additionally-content {
    width: 100%;
    margin-top: auto;
  }
}
