.input-da-data {
  position: relative;
  width: 100%;

  .input {
    position: relative;
    z-index: 2;
  }

  &__addresses {
    margin-top: 4px;
    position: absolute;
    width: 100%;
    border: 1px solid $gray-light-30;
    border-radius: 8px;
    box-shadow: none;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
    z-index: 2;
    overflow: hidden;
  }

  &__address {
    @include text-1;
    padding: 16px;
    border-bottom: 1px solid $gray-light-30;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    hyphens: auto;
    background-color: white;

    &:hover {
      color: $black;
      background: $gray-light-10;
    }

    &:last-child {
      border: none;
    }
  }

  &__background {
    position: absolute;
    z-index: 1;
    display: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;

    &_visible {
      display: block;
    }
  }
}
