.device-log {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__tab-pane-container {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: max-content;
  }

  &__tab-pane-text {
    max-width: 100px;
    white-space: normal;
  }

  &__loader-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
