.flats-list {
  &__tools {
    margin-bottom: 32px;

    &-item {
      margin-bottom: 16px;
      display: flex;
      align-items: end;

      & > div {
        margin-right: 24px;

        &:last-child {
          margin-right: 0;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      .select {
        width: 100%;
        max-width: 32%;
      }
    }
  }

  &__search {
    width: 100%;
  }
}
