.tariffs-cost-page {
  .ant-tabs-tab {
    min-height: 100px;
  }

  .input-search {
    flex: 3;
  }

  .ant-tabs-nav-more {
    display: none;
  }

  .ant-tabs-nav::before,
  .ant-tabs-ink-bar {
    display: none;
  }
}
