.tab-switch {
  background-color: $gray-light-50;
  display: flex;
  width: fit-content;
  border-radius: 12px;

  &__item {
    padding: 0 16px;
    @include text-1;
    border-radius: 12px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    cursor: pointer;

    &_active {
      background-color: $white;
      color: $blue;
      border: 1px solid $blue;
      font-weight: 700;
    }
  }
}
