.instructions-chapter {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__title {
    @include help-header-0;
    color: $black;
  }

  &__content {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    gap: 16px;
    margin-top: 24px;
  }
}
