.device-log-list {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__pagination {
    margin-top: auto !important;
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__loader-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__error {
    height: auto;
    padding: 0;
  }

  &__item-container {
    border: 1px solid rgba($black, 0.1);
    border-radius: 8px;
  }

  &__item {
    padding: 23px 20px 22px 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    column-gap: 24px;
    border-bottom: 1px solid rgba($black, 0.1);
    @include text-2;
    color: $black;

    &:last-child {
      border-bottom: none;
      padding-bottom: 23px;
    }
  }

  &__item-name {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    @include table-body-text-0;
    font-weight: 600;
    text-align: left;
  }

  &__item-date {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: rgba($black, 0.6);
  }

  &__item-action {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
  }
}

