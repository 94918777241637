.profile-popover {
  &__children-wrapper {
    cursor: pointer;
  }

  &__dropdown {
    .ant-popover-inner {
      min-width: 204px;
      border-radius: 8px;
      background: $white;

      .ant-popover-inner-content {
        padding: 16px;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__info {
    width: 100%;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    row-gap: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid $gray-500;
  }

  &__text {
    @include input-text-0;
    color: $black;
  }

  &__name {
    @include text-4;
    color: $black;
  }

  &__button-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 16px;
  }

  &__button-exit {
    display: flex;
    align-items: center;
    @include button-text-0;
    font-family: $sf-pro-text;
    padding: 0;
    border: none;
  }

  &__button-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;

    svg {
      width: 100%;
      height: 100%;
      fill: $black;
    }
  }
}
