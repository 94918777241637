.section-call-codes {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__content-wrapper {
    width: 100%;
    height: 100%;
    margin-top: 32px;
  }

  &__button {
    min-width: 121px;
  }
}
