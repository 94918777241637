.section-flats {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__hide {
    display: none;
  }

  &__info {
    margin-bottom: 8px;
    @include text-1;
    color: $gray-medium;
  }
}
