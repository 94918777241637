.help-faq-item {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__back-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__loader-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px;
  }

  &__error-wrapper {
    width: 100%;
    height: 100%;
    padding: 32px !important;
  }

  &__title {
    margin-top: 32px;
    width: 100%;
    @include help-header-0;
    color: $black;
  }

  &__body {
    margin-top: 24px;
    width: 100%;
    @include input-text-0;
    color: $black;
  }
}
