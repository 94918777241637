.info-pop-up {
  position: absolute;
  z-index: 2;
  bottom: 48px;
  left: 22px;
  background-color: $white;
  padding: 8px;
  border-radius: 8px;
  border-bottom-left-radius: 0;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);

  .dispatcher-info-item__list {
    border-bottom: 0;

    &-item {
      padding: 4px 0 0 33px;
      @include text-0;
      font-size: 12px;
      text-wrap: nowrap;
    }
  }
}
