.disabling-payment {
  margin-top: 32px;

  &__title {
    margin-bottom: 25px;
    display: flex;
    align-items: center;

    &-label {
      @include help-header-0;
    }

    &-btn {
      margin-left: auto;
    }
  }

  &__sub-title {
    @include text-1;
    color: $gray-medium;
    margin-bottom: 24px;
  }
}
