.upload-subscribers {
  &__info {
    @include text-1;
    margin-bottom: 16px;
  }

  &__cell {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 80px;
    height: 100%;
    padding: 16px 12px;
    max-width: 250px;

    &-data {
      @include text-1;
    }

    &-errors {
      padding-top: 16px;
      @include text-0;
      color: $red;
      font-size: 12px;
    }

    &-borders {
      border: 1px solid $gray-light-20;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;

      &_error {
        border: 1px solid $red !important;
      }
    }
  }

  .editable-table {
    max-height: 571px;
    overflow-y: scroll;

    td.ant-table-cell {
      border: none !important;
      position: relative;
    }
    th.ant-table-cell {
      border: 1px solid $gray-light-20;
    }
  }

  &__button-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
