.flat-journal-tab {
  .payments-filters__inputs {
    .select-multiple {
      margin-right: 24px;
    }

    & > div {
      max-width: 32%;

      &:last-child {
        margin-right: 0;
      }
    }

    .date-picker {
      margin-right: 24px;
    }
  }
}
