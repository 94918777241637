.add-call-codes-modal {
  display: flex;
  flex-direction: column;

  &__wrapper {
    .ant-modal-header {
      padding-bottom: 16px;
    }

    .ant-modal-footer {
      padding-top: 8px;
    }
  }

  &__button-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__field-wrapper {
    margin-top: 16px;
    display: flex;
    flex-direction: column;

    &:first-child {
      margin-top: 0;
    }
  }

  &__title {
    @include heading-4;
    color: $black;
  }

  &__radio-group-container {
    margin-top: 16px;
  }

  &__input-title {
    @include input-title-0;
    color: $gray-900;
    margin-bottom: 6px;
  }

  &__input-container {
    display: flex;
    align-items: flex-start;
  }

  &__input-item {
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__input {
    text-align: center;
  }

  &__error-wrapper {
    min-height: 38px;
  }
}
