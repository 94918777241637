.status-select {
  &__select-container {
    width: 100%;

    .ant-select-disabled {
      .status-select__icon {
        svg {
          fill: $gray-medium !important;
        }
      }

      .ant-select-arrow {
        opacity: 0.4;
      }
    }
  }

  &__select {
    .ant-select-selector {
      padding-top: 11px !important;
      padding-bottom: 11px !important;
    }
  }

  &__content {
    display: flex;
    align-items: center;
  }

  &__icon {
    width: 24px;
    height: 24px;
    margin-right: 4px;

    svg {
      width: 100%;
      height: 100%;
      fill: transparent;
    }

    &_green {
      svg {
        fill: $green;
      }
    }

    &_red {
      svg {
        fill: $red;
      }
    }

    &_gray {
      svg {
        fill: $gray-medium;
      }
    }

    &_yellow {
      svg {
        fill: $yellow;
      }
    }
  }
}
