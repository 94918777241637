.flats-page-table {
  &__status {
    padding: 8px 16px;
    border-radius: 8px;
    white-space: nowrap;

    &_active {
      background-color: rgba($green, 0.2);
    }

    &_inactive {
      background-color: rgba($gray-light-20, 0.5);
    }
  }

  &.editable-table {
    .ant-table-cell {
      cursor: pointer;
      .editable-table__data-container,
      .editable-table__icon-container {
        padding: 0 12px;
        min-height: 56px;
        hyphens: auto;
      }

      .editable-table__icon-container {
        opacity: 0;
      }
    }

    .ant-table-thead > tr > th {
      padding: 17px 12px;
    }

    .ant-table-row {
      &:hover {
        .editable-table__icon-container {
          opacity: 1;
        }
      }
    }
  }

  &_absolute {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 23px;
    background-color: white;
    padding: 16px 0;
  }
}
