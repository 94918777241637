.clear-call-codes-modal {
  display: flex;
  flex-direction: column;

  &__wrapper {
    .ant-modal-header {
      padding-bottom: 16px;
    }

    .ant-modal-title {
    }

    .ant-modal-footer {
      padding-top: 8px;
    }
  }

  &__button-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__field-wrapper {
    margin-top: 16px;
    display: flex;
    flex-direction: column;

    &:first-child {
      margin-top: 0;
    }
  }

  &__error-wrapper {
    min-height: 38px;
  }
}
