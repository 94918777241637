.subscribers {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__tools {
    button {
      margin-left: 24px;

      .subscribers__button-icon {
        svg {
          @include transition-standard();
          scale: 0.7;
          path {
            stroke: $white;
          }
        }
      }

      &.ant-dropdown-open {
        .subscribers__button-icon {
          svg {
            transform: rotate(-180deg);
          }
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__range-dropdown {
    border-radius: 8px;
    border: 1px solid $gray-400;
    padding: 0;
    overflow: hidden;

    &-item {
      padding: 16px;
      @include text-1;
      border-bottom: 1px solid $gray-400;
    }
  }

  &__tools-item {
    width: 100%;
    min-width: 212px;
  }

  &__search {
    width: 100%;
  }

  &__select {
    width: 100%;
  }

  &__button {
    min-width: 212px;
    justify-content: center;
  }

  &__content {
    width: 100%;
  }
}
