.dispatcher-panel {
  &__tools {
    .select,
    .select-multiple {
      max-width: 32%;
    }
  }

  &__disable-push {
    margin-bottom: 32px;

    &_mt {
      margin-top: 22px;
    }

    .disabling-push__buttons {
      justify-content: space-between;

      &-push {
        margin-left: 24px;
      }
    }
  }

  &__status {
    &::before {
      content: '';
      display: inline-block;
      min-width: 8px;
      min-height: 8px;
      border-radius: 100%;
      transform: translate(-10px, -1px);
    }

    &_green {
      &::before {
        background-color: #48c80b;
      }
    }
    &_yellow {
      &::before {
        background-color: #ffe600;
      }
    }
    &_red {
      &::before {
        background-color: #f00200;
      }
    }
    &_blue {
      &::before {
        background-color: #1344f2;
      }
    }
    &_gray {
      &::before {
        background-color: #949494;
      }
    }
  }

  &__content {
    height: 100%;
    display: flex;
  }

  .tabs {
    width: auto;
  }

  &__tabs {
    .ant-tabs-tab-active {
      border: 1px solid $blue;

      .tabs__tab-title {
        color: inherit;
      }
    }

    .tabs__tab-title {
      color: $black;
    }

    .ant-tabs-nav-list {
      background-color: $gray-light-50;
    }

    .ant-tabs-nav {
      margin-bottom: 0;
    }
  }

  &__multiselect {
    min-width: 275px;
  }
}
