.tariffs-table {
  .ant-table table {
    border: 1px solid $gray-light-30;
    border-radius: 8px;
    overflow: hidden;
  }

  &__header {
    display: flex;
    @include heading-4;

    &-name {
      &_disabled {
        color: $gray-medium;
      }
    }

    .ant-checkbox-wrapper {
      margin-right: 9px;
    }
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    height: 0;
  }
}
