.notify {
  &__stack {
    background: none !important;
    box-shadow: none !important;
    margin-bottom: 20px !important;
    padding: 0 !important;
    overflow: visible !important;
    min-height: auto !important;
    max-height: fit-content !important;
    @include transition(all, 0.3s, ease-out);

    &-wrapper {
      display: flex;
      flex-direction: column;
    }

    &_close {
      @include transition(all, 0.4s, ease-out);

      .notify__stack-buttons {
        opacity: 0;
        max-height: 0;
        box-shadow: none;
        margin-bottom: 0;
        transform: translate(0, 50px);
      }

      .notify__stack-item {
        position: absolute;
        top: 0;
        z-index: 0;
        visibility: hidden;
        pointer-events: none;
        transform: translate(0, -1px) scale(0.93);
      }

      .notify__stack-item_first {
        position: relative;
        visibility: visible;
        z-index: 3;
        margin-bottom: 0;
        pointer-events: all;
        transform: translate(0, 0);

        .notify__message {
          margin-bottom: 0 !important;
          position: relative;
          z-index: 3 !important;
        }

        .notify__stack-item-second-layer {
          position: absolute;
          z-index: 2;
          top: auto;
          left: 8px;
          bottom: -8px;
          height: 33px;
          width: 400px;
          background-color: white;
          border-radius: 24px;
          box-shadow: 0px 7px 15px 2px rgba(0, 0, 0, 0.25);
          @include transition(all, 0.3s, ease-out);
        }

        &:hover {
          .notify__stack-item-second-layer {
            transform: scale(1.04) translate(0, 6px);
          }
        }
      }
    }

    &-buttons {
      display: flex;
      margin-bottom: 20px;
      margin-left: auto;
      @include transition(all, 0.4s, ease-out);

      &-show {
        display: flex;
        width: 201px;
        padding: 14px 20px 14px 16px;
        background-color: $white;
        border-radius: 24px;
        overflow: visible;
        cursor: pointer;
        box-shadow: 0px 7px 15px 2px rgba(0, 0, 0, 0.25);
        @include transition(all, 0.3s, ease-out);

        &-arrow {
          transform: rotate(180deg) translate(5px, 1px);
        }

        &-label {
          display: flex;
          align-items: center;
          @include text-1;
          color: $black;
        }

        &:hover {
          @include transform(1.1);
        }
      }

      &-close {
        height: 52px;
        width: 52px;
        border-radius: 100%;
        background-color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 16px;
        cursor: pointer;
        box-shadow: 0px 7px 15px 2px rgba(0, 0, 0, 0.25);
        @include transition(all, 0.3s, ease-out);

        svg {
          transform: scale(0.54);
        }

        &:hover {
          @include transform(1.1);
        }
      }
    }
    .notify__message {
      &:last-child {
        margin-bottom: 0px !important;
      }
    }
  }

  &__stack-item {
    margin-bottom: 20px;
    @include transition(all, 0.5s, ease-in);

    &_hide {
      @include transition(all, 0.3s, ease-out);
      transform: translate(500px, 0);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__stack-item_first {
    .notify__message {
      margin-bottom: 0 !important;
    }
  }
}