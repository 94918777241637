.available-user-for-role {
  &__content {
    margin-top: 24px;

    .ant-table-wrapper {
      height: 640px;
      margin-bottom: 20px;
    }

    .ant-pagination {
      position: absolute;
      bottom: 0;
    }

    .editable-table {
      &__data-container {
        padding: 18px 12px;
        height: 48px;
        min-height: auto;
        @include text-1;
      }
    }

    .ant-table-thead > tr > th {
      padding: 17px 12px;
      height: 56px;
    }
  }

  &__button-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .ant-btn {
      height: 48px;
    }
  }

  &__wrapper {
    .ant-modal-header {
      padding-bottom: 0;
    }

    .ant-modal-title {
      @include help-header-0;
      min-height: 32px;
      padding: 0;
      margin-bottom: 40px;
    }

    .ant-modal-body {
      padding-top: 4px;
      padding-bottom: 20px;
    }

    .ant-modal-footer {
      padding-top: 0;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  &__counter {
    position: absolute;
    bottom: 76px;
    color: $gray-medium;
  }
}
