.email-editor {
  &__text-info {
    @include text-1;
    color: $gray-medium;
  }

  &__title {
    @include help-title-0;
  }

  .information-block {
    margin-top: 12px;
    margin-bottom: 32px;
  }

  .mailing__status {
    padding: 6px 16px;
  }

  &__container {
    display: flex;
    width: 100%;
    border: 1px solid $gray-light-30;
    border-radius: 8px;
    overflow: hidden;
    margin: 16px 0;

    &-title {
      padding: 10px 8px;
      border-bottom: 1px solid $gray-light-30;
    }
  }

  &__mail-info {
    display: flex;
    align-items: flex-end;
    margin-bottom: 32px;

    .input {
      width: 100%;
    }

    .single-date-picker {
      min-width: 272px;
    }

    & > div {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__test-send {
    display: flex;
    align-items: flex-end;
    margin-top: 16px;

    .input {
      margin-right: 16px;
      width: 370px;
    }

    .ant-btn {
      height: 48px;
    }
  }

  &__editor {
    width: 100%;
    border-right: 1px solid $gray-light-30;

    &_disabled {
      pointer-events: none;
    }

    .minimap.slider-mouseover {
      transform: translate(8px, 0px);
    }

    .decorationsOverviewRuler {
      width: 0 !important;
    }

    .monaco-scrollable-element > .scrollbar > .slider {
      border-radius: 10px;
      cursor: pointer;
      background: $gray-medium !important;
    }

    .monaco-scrollable-element > .scrollbar.vertical {
      width: 6px !important;

      .slider {
        width: 6px !important;
      }
    }

    .monaco-scrollable-element > .scrollbar.horizontal {
      height: 6px !important;

      .slider {
        height: 6px !important;
      }
    }
  }

  &__frame {
    width: 100%;

    iframe {
      height: 100%;
      width: 100%;
    }
  }

  &__textarea, &__input {
    max-width: 760px;
  }

  &__input {
    margin-bottom: 16px;
    margin-top: 16px;
  }

  &__sms-counter {
    @include text-1;
    color: $gray-medium;
    margin-top: 6px;
    margin-bottom: 16px;
  }
}
