.call-codes-tab {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 16px;

  &__button-next {
    margin-left: auto;
  }

  &__check-button {
    margin-bottom: 32px;
    
    .ant-btn {
      @include button-text-0;
    }
  }
}
