.subscriptions-table {
  position: relative;

  &__settings {
    position: absolute;
    z-index: 10;
    top: 18px;
    left: 11px;
    cursor: pointer;

    &_active {
      svg {
        path {
          stroke: $blue;
          fill: $white;
        }
        circle {
          stroke: $blue;
          fill: $white;
        }
      }
    }
  }

  &__menu {
    padding: 12px 5px;

    .ant-dropdown-menu-item {
      width: 220px;

      .close-icon {
        z-index: 10;
        width: 32px;
        height: 32px;
      }

      &:hover {
        background-color: inherit;
      }

      &-icon {
        margin-right: 11px;
      }
    }
  }

  tbody tr {
    .ant-table-cell {
      &:first-child {
        min-width: 140px;
        padding-left: 40px;
      }
    }
  }

  thead tr {
    .ant-table-cell {
      &:first-child {
        min-width: 140px;
        padding-left: 50px;
      }
    }
  }

  &__title-light {
    font-size: 14px;
    font-weight: 400;
    white-space: nowrap;
  }

  &__status {
    padding: 8px 16px;
    border-radius: 8px;
    white-space: nowrap;

    &_Created {
      background-color: rgba($orange, 0.2);
    }

    &_Paid {
      background-color: rgba($green, 0.2);
    }

    &_Canceled {
      background-color: $gray-light-30;
    }

    &_NoPaid {
      background-color: rgba($red, 0.2);
    }
  }

  &.editable-table {
    .ant-table-thead > tr > th {
      height: 58px;
    }
  }
}
