.faq-section-modal {
  &__button-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__wrapper {
    .ant-modal-header {
      padding-bottom: 0;
    }

    .ant-modal-title {
      min-height: 32px;
      padding: 0;
    }

    .ant-modal-body {
      padding-top: 16px;

      .input {
        margin-bottom: 24px;
      }
    }

    .ant-modal-footer {
      padding-top: 16px;
    }
  }

  &__notify {
    display: flex;
    margin-top: 16px;

    &-label {
      @include text-0;
    }

    &-icon {
      margin-right: 10px;
    }
  }
}
