.contacts-section {
  .input-search {
    margin-bottom: 24px;
  }

  .error-placeholder,
  .tabs-custom__loader-wrapper {
    height: 219px;
    position: inherit;
  }

  &__title {
    @include text-4;
    font-weight: 700;
    margin-bottom: 8px;
  }

  &__notify {
    @include text-1;
    margin-bottom: 8px;
    color: $gray-medium;
    margin-bottom: 12px;
  }

  &__contacts {
    display: flex;
    margin-bottom: 32px;

    & > div {
      margin-left: 24px;
      max-width: 248px;
      width: 100%;

      &:first-child {
        margin-left: 0;
        max-width: none;
      }
    }
  }

  .input-search {
    margin-top: 4px;
  }

  .ant-tabs-tab {
    min-height: 100px;
  }

  .ant-tabs-nav-more {
    display: none;
  }

  .ant-tabs-nav::before,
  .ant-tabs-ink-bar {
    display: none;
  }

  .tab-nav-buttons__button-save {
    margin-right: 0;
  }

  .tab-nav-buttons__button-cancel {
    margin-left: 16px;
  }
}
