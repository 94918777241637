.editable-block {
  display: flex;
  position: relative;
  cursor: pointer;

  &:hover {
    .editable-block__icon {
      visibility: visible;
    }
  }

  &__icon {
    visibility: hidden;
    margin-left: 8px;
    min-width: 20px;

    &:hover {
      svg {
        path {
          stroke: $blue;
        }
      }
    }

    svg {
      position: absolute;
      height: 20px;
      width: 20px;
    }
  }
}
