.object-keys-row {
  position: relative;
  top: 2px;
  margin: 10px 0;

  &__key {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border: 1px solid $gray-light-30;
    border-radius: 8px;
    margin-right: 8px;
    cursor: pointer;
    @include transition-standard();

    &-content {
      margin-right: 12px;
    }

    &-name {
      @include tooltip-text-0;
      font-size: 10px;
      color: $gray-medium;
    }

    &-alias {
    }

    &-icons {
      @include text-1;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      border-color: $blue;
      background-color: $blue-light;
    }
  }

  &__key-icon {
    height: 14px;
    width: 14px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 100%;
      width: 100%;
    }

    &_edit {
      &:hover {
        svg {
          path {
            stroke: $blue;
          }
        }
      }
    }

    &_delete {
      margin-bottom: 4px;

      svg {
        fill: $gray-medium;
      }

      &:hover {
        svg {
          fill: $blue;
        }
      }
    }
  }
}
