.sidebar {
  width: $sidebar-width;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: $blue;
  overflow-x: hidden;
  @include transition-standard;

  &_expanded {
    width: $sidebar-expanded-width;
  }

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 26.5px 43px;

    &_expanded {
      justify-content: flex-start;
      padding: 26.5px 14px 26.5px 43px;
    }
  }

  &__expand-button {
    cursor: pointer;
  }

  &__logo {
    margin-left: 20px;
    height: 32px;

    svg {
      fill: $white;
    }
  }

  &__tabs-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow-y: scroll;
  }

  &__tab-logo {
    width: 32px;
    min-width: 32px;
    height: 32px;
    min-height: 32px;

    svg {
      width: 100%;
      height: 100%;
    }

    &_fill-white {
      svg {
        fill: $white;
      }
    }
  }
}
