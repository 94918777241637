.faq-subscribers {
  position: fixed;
  padding-right: 20px;
  width: -webkit-fill-available;

  &__first-select {
    min-width: 328px;
    max-width: 328px;
  }

  &__second-select {
    max-width: 328px;
  }

  &__content {
    display: flex;

    &_short {
      .faq-section__content {
        height: calc(100vh - 360px);
      }

      .faq-table__tbody {
        max-height: calc(100vh - 360px);
      }

      .faq-table_short {
        .faq-table__tbody {
          max-height: calc(100vh - 427px);
        }
      }

      .faq-section_short {
        .faq-section__content {
          max-height: calc(100vh - 427px);
        }
      }
    }
  }

  &__status {
    span {
      padding: 0 14px;
    }

    &::before {
      content: '';
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 100%;
    }

    &_active {
      &::before {
        background-color: $green;
      }
    }

    &_inactive {
      &::before {
        background-color: $red;
      }
    }
  }

  .tab-nav-buttons {
    z-index: 12;
  }
}
