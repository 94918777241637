.notification-modal {
  &__button-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__wrapper {
    .ant-modal-header {
      padding-bottom: 0;
    }

    .ant-modal-title {
      padding: 0;
    }

    .ant-modal-footer {
      padding-top: 40px;
    }
  }

  &__body {
    padding-top: 28px;
    @include button-text-0;
    color: $black;
  }
}
