.configuration-tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin-top: 16px;

  &__title {
    @include text-4;
    text-align: center;
    color: $black;
    margin-bottom: 0;
    margin-top: 8px;
  }

  &__sub-title {
    text-align: center;
    color: $black;
    font-weight: 600;
    margin-top: 20px;
  }

  &__icon {
    width: 40px;
    height: 40px;
    margin-bottom: 8px;
  }

  &__error-message {
    margin-top: 16px;
  }

  &__text {
    text-align: center;
    @include text-0;
    margin-bottom: 15px;

    &_notify {
      margin-bottom: 0px;
      margin-top: 8px;
    }
  }

  &__loader {
    margin-top: 40px;
  }

  .ant-btn {
    width: 212px;
    height: 48px;
    justify-content: center;
    margin-top: 24px;
  }

  &__buttons {
    display: flex;
    .ant-btn {
      margin-right: 24px;
      margin-top: 9px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__errors {
    margin-top: 24px;
  }

  .tab-nav-buttons {
    .ant-btn {
      margin: 0 0 0 auto;
    }
  }
}
