.users-table {
  &__message-disabled {
    @include text-1;
    color: $gray-medium;
  }

  &__object {
    display: flex;
    margin-bottom: 10px;
  }

  &__status {
    height: 8px;
    width: 8px;
    border-radius: 8px;
    white-space: nowrap;
    margin-right: 8px;
    transform: translate(0px, -1px);
    background-color: $red;

    &_active {
      background-color: $green;
    }
  }

  .editable-table__data-container_no-flex {
    padding-top: 10px !important;
    align-items: baseline;
    justify-content: center;
    flex-direction: column;
  }

  &.editable-table {
    .ant-table-cell {
      cursor: pointer;
      .editable-table__data-container,
      .editable-table__icon-container {
        padding: 0 12px;
        min-height: 56px;
        hyphens: auto;
      }
    }

    .ant-table-thead > tr > th {
      padding: 17px 12px;
    }
  }
}
