.error-placeholder {
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  &__icon-container {
    width: 44px;
    height: 44px;

    svg {
      width: 100%;
      height: 100%;
      fill: $blue;
    }
  }

  &__text {
    margin-top: 8px;
    @include input-text-0;
    color: $black;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
