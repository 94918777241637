.notify {
  &__message {
    position: relative;
    width: 416px;
    background: $white !important;
    margin-bottom: 20px !important;
    border-radius: 20px !important;
    overflow: visible !important;
    min-height: auto !important;
    max-height: fit-content !important;
    padding: 0 !important;
    @include transition(all, 0.3s, ease-out);
    box-shadow: 0px 7px 15px 2px rgba(0, 0, 0, 0.25) !important;
    cursor: pointer !important;

    &-content {
      padding: 16px 44px !important;
    }

    &-close {
      transform: scale(0.54);
      position: absolute;
      right: 16px;
      top: 6px;
    }

    &-title {
      @include text-1;
      font-weight: 700;
      margin-bottom: 8px;
      color: $black;
    }

    &-text {
      @include text-0;
      color: $black;
    }

    &::before {
      content: '';
      position: absolute;
      display: inline-block;
      width: 16px;
      height: 16px;
      border-radius: 100%;
      transform: translate(16px, 16px);
    }

    &_yellow {
      &::before {
        background-color: #ffe600;
      }
    }
    &_red {
      &::before {
        background-color: #f00200;
      }
    }
    &_gray {
      &::before {
        background-color: #949494;
      }
    }

    &:hover {
      @include transform(1.04);
    }
  }
}
