.cam-options {
  &__content {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    row-gap: 24px;
    margin-bottom: 24px;
    background-color: $gray-light-10;
    border: 1px solid $gray-light-30;
    border-radius: 8px;
    padding: 16px;
  }

  &__warning {
    color: $gray-medium;
    @include text-1;
  }

  &__error {
    color: $red;
    @include text-1;
  }

  &__cam-options-grid-row {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    column-gap: 24px;

    & > button {
      margin-top: auto;

      span {
        margin: auto;
      }
    }

    &__extend {
      grid-template-columns: repeat(3, 1fr);
      align-items: flex-start;
    }
  }
}
