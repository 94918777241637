.mailing {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    &-count {
      @include text-1;
      color: $gray-medium;
      margin-left: auto;
      text-align: center;
      width: 221px;

      &-number {

        &_error {
          color: $red;
        }
      }
    }
  }

  &__button {
    margin-left: auto;
    margin-bottom: 32px;
  }

  &__status {
    padding: 8px 16px;
    border-radius: 8px;
    white-space: nowrap;
    width: fit-content;

    &_Waiting,
    &_Created {
      background-color: rgba($orange, 0.2);
    }

    &_Sending,
    &_Completed {
      background-color: rgba($green, 0.2);
    }

    &_Draft,
    &_Canceled {
      background-color: $gray-light-30;
    }

    &_NotSending,
    &_Failed {
      background-color: rgba($red, 0.2);
    }
  }
}
