.security-decks-table {
  &__info {
    display: flex;

    .tooltip__wrapper {
      transform: translate(3px, 3px);
      height: 15px;
    }
  }
}
