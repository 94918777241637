.organization-main-info {
  &__company {
    display: flex;
    margin-bottom: 32px;

    .ant-btn {
      height: 48px;
      margin-left: auto;
    }
  }

  &__logo-container {
    margin-right: 32px;
  }

  &__logo {
    margin-bottom: 8px;

    &-img {
      height: 90px;
      width: 90px;
      border-radius: 100%;
      border: 1px solid $gray-light-30;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 100%;
      }
    }
  }

  &__logo-edit {
    @include text-0;
    color: $blue;
    cursor: pointer;
  }

  &__company-name {
    @include help-header-0;
    margin-bottom: 12px;
  }

  &__company-address {
    color: $gray-medium;
  }

  &__company-integration {
    display: flex;
    margin-top: 12px;

    &_success {
      color: $green;
    }

    &_fail {
      color: $red;
    }

    &-icon {
      margin-right: 3px;
    }
  }

  &__contacts-container {
    display: flex;
    margin-bottom: 32px;

    & > div:first-child {
      margin-right: 32px;
    }
  }

  &__contact {
    width: 100%;

    &-title {
      @include text-4;
      margin-bottom: 12px;
    }

    &-card {
      padding: 16px;
      border: 1px solid $gray-light-30;
      border-radius: 8px;
      min-height: 140px;
    }

    &-item {
      margin-bottom: 16px;
      display: flex;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-label {
      min-width: 160px;
      width: 100%;
      max-width: 320px;
      @include text-0;
      color: $gray-medium;
    }

    &-value {
      width: 100%;
    }
  }
}
