.systems-new {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__button-wrapper {
    margin-bottom: 32px;
  }

  &__content {
    margin: 0 0 20px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__input-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
    row-gap: 24px;
    margin-bottom: 24px;
  }

  &__selects-container {
    display: flex;

    & > div {
      width: 100%;
      margin-right: 24px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__input {
    padding: 14.5px 16px !important;
  }

  &__button-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-top: auto;
    padding: 16px 24px;
  }

  &__button {
    width: 212px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
