.hardware-validate-buttons {
  height: 53px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
  width: 100%;
  overflow-y: scroll;

  .ant-picker-dropdown {
    position: fixed;
    top: 300px !important;
  }

  &__container {
    display: flex;
    margin-right: 10px;

    &:first-child {
      margin-right: auto;
      margin-left: 0 !important;

      .ant-btn {
        margin-left: 0;
      }
    }
  }

  &__date-picker {
    margin-left: 24px;
    margin-right: 10px;

    .single-date-picker__input-field {
      min-width: 230px;
      white-space: nowrap;
      @include text-1;
    }
  }

  .ant-btn {
    @include button-text-0;
    height: 46px;
  }

  &__errors {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 24px;

    &-button {
      height: 48px;
      width: 48px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &_disabled {
        cursor: default;
        pointer-events: none;
        svg {
          path {
            stroke: $gray-500;
          }
        }
      }
    }

    &-label {
      margin: 0 12px;
      @include text-1;
      cursor: default;
      user-select: none;

      &_disabled {
        color: $gray-500;
      }
    }
  }
}

@include breakpoint-down($grid-breakpoint-xxl) {
  .hardware-validate-buttons {
    display: -webkit-inline-box;

    &__container {
      &:last-child {
        margin-left: auto;
      }
    }

    &__check-errors-button {
      margin-left: 31px;
    }
  }
}
