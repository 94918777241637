.create-notification {
  &__button-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__wrapper {
    .ant-modal-header {
      padding-right: 68px;
      padding-bottom: 0;

      .ant-modal-title {
        font-weight: 700;
        padding: 0;
      }
    }

    .ant-modal-footer {
      padding-top: 40px;
    }
  }
}
