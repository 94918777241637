.directory-tree {
  width: 100%;
  height: 100%;

  &__img-title {
    &-content {
      display: flex;
      align-items: center;
    }

    &-icon {
      margin-right: 8px;
    }

    &-label {
      @include text-1;
    }
  }

  &__invalid-node {
    background-color: $red-light;
  }

  &__content-wrapper {
    padding-right: 14px;
  }

  &__new-item {
    @include tooltip-text-0;
    color: $blue;
  }

  &__loader-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
  }

  .ant-tree-switcher {
    width: 22px;
    display: flex;
    justify-content: center;
    position: relative;
    top: 3px;

    svg {
      transition-duration: 0.3s;
      transition-property: transform;
      transform: rotate(-90deg);
    }
  }

  .ant-tree-switcher_open {
    svg {
      transform: rotate(0);
    }
  }

  .ant-tree-node-content-wrapper {
    padding: 0 8px;
    @include heading-3;
    font-weight: 500;
    color: $black;
    min-height: 18px;
    transition-duration: 0s;
    overflow: hidden;
  }

  .ant-tree.ant-tree-directory {
    .ant-tree-root {
      border-bottom: 1px solid rgba($black, 0.1);
      padding: 14px 0;

      .ant-tree-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;

        svg {
          display: none;
        }
      }
    }
  }

  .ant-tree-title {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: $black;
    cursor: pointer;

    &:hover {
      .ant-tree-title-icons {
        opacity: 1;
        @include transition-opacity-standard();
      }
    }
  }

  .ant-tree-title-name {
    margin-bottom: 4px;
    @include text-0;
    display: flex;
    align-items: center;

    &__icon {
      margin-left: auto;
      opacity: 0;
    }

    &_bold {
      font-size: 16px;
      font-weight: bold;
    }

    &:hover {
      .ant-tree-title-name__icon {
        opacity: 1;
      }
    }
  }

  .ant-tree-title-address {
    font-size: 12px;
    color: $gray-medium;
    line-height: 14px;
  }

  .ant-tree-title-icons {
    display: flex;
    position: absolute;
    background-color: $gray-light-10;
    opacity: 0;
    top: -4px;
    right: -9px;
    padding-left: 2px;

    svg {
      width: 16px;
      height: 24px;
      margin-right: 8px;

      &:hover {
        path {
          stroke: $blue;
        }
      }
    }
  }

  .ant-tree-treenode {
    padding: 5px 0;
    min-height: 40px;
    align-items: center;

    &:before {
      bottom: 0 !important;
    }
  }

  .ant-tree.ant-tree-directory .ant-tree-treenode:hover::before {
    background-color: $gray-light-10;
  }

  .ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before {
    background-color: $blue-light;
  }

  .ant-tree-treenode-selected {
    .ant-tree-title-icons {
      background-color: $blue-light;
    }
  }
}
