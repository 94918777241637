.call-codes-check-modal {
  display: flex;
  flex-direction: column;

  .ant-modal {
    position: relative;
  }

  .ant-tabs-content {
    position: initial;
  }

  &__wrapper-no-errors {
    .ant-modal {
      top: 40%;
      position: relative;
    }
  }

  &__wrapper {
    .ant-modal-header {
      padding-bottom: 16px;
    }

    .ant-modal-close {
      z-index: 0;
    }

    .ant-modal-footer {
      margin-top: auto;
      padding-top: 8px;
    }
  }

  &__wrapper-content {
    .ant-modal-content {
      min-height: 862px;
      display: flex;
      flex-direction: column;
    }
  }

  &__button-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .tabs-custom .ant-tabs-nav {
    margin-bottom: 40px;
  }

  &__row-selected-drop {
    position: fixed;
    z-index: 1;
    display: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;

    &_visible {
      display: block;
    }
  }

  .editable-table__row-selected-drop {
    z-index: 2;
  }

  .editable-table__row {
    z-index: 3;
  }

  .ant-table {
    min-height: 468px;
    border: none;

    &-container {
      border: 1px solid #e9e9e9;
      border-radius: 8px;
      overflow: hidden;
    }

    .input .ant-input-status-error {
      background: $white !important;
    }
  }

  .ant-tabs-nav-more {
    display: none;
  }

  .ant-tabs-nav::before,
  .ant-tabs-ink-bar {
    display: none;
  }
}
