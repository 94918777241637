.schema-buttons {
  position: absolute;
  width: 100%;

  &__content {
    overflow-y: hidden;
    overflow-x: auto;
    width: 100%;
    padding: 0;

    &::-webkit-scrollbar {
      background: inherit !important;
    }
  }

  &__buttons {
    margin-bottom: 8px;
    display: flex;
    align-items: flex-end;
    width: 100%;
  }

  &__checkboxes {
    min-height: 44px;
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 8px;
  }

  .ant-checkbox-group {
    display: flex;

    .ant-checkbox-wrapper {
      width: 180px;
    }
  }

  &__create-dropdown {
    border-radius: 8px;
    border: 1px solid $gray-400;
    padding: 0;
    overflow: hidden;

    &-item {
      padding: 0;
      border-bottom: 1px solid $blue-light;

      &:last-child {
        border: $white;
      }
    }
  }

  &__range-dropdown {
    border-radius: 8px;
    border: 1px solid $gray-400;
    padding: 10px 8px;
    overflow: hidden;

    &-item {
      &:hover {
        background-color: white !important;
      }
    }
  }

  &__button {
    @include button-text-1;
    padding: 11px 14px;
    margin-right: 16px;

    &.ant-dropdown-open {
      .schema-buttons__button-icon {
        transform: rotate(-180deg);
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__button-icon {
    margin-left: 11px;
    @include transition-standard();
  }

  &__container-menu {
    padding: 16px;

    &:hover {
      background-color: inherit;
    }
  }

  &__menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 145px;
    margin: auto;
  }

  &__menu-button {
    display: flex;
    align-items: center;
    padding: 10px 19px 10px 16px;
    color: $blue;
    height: 52px;
    @include button-text-1;
  }

  &__menu-button-icon {
    display: flex;
    align-items: center;
    margin-left: auto;
    @include transition-standard();

    &_rotate {
      transform: rotate(-180deg);
    }
  }

  &__menu-button-label {
    max-width: 125px;
  }

  &__menu-item {
    height: 60px;
    min-width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    @include transition(all, 0.3s, ease-out);

    &:hover {
      @include transform(1.1);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .tabs__tabs-button {
    &_left {
      transform: translate(0, 0);
      top: 22px;
    }

    &_right {
      transform: none;
      left: auto;
      right: 0;
      top: 22px;
    }
  }

  .input-slider {
    width: 150px;
  }

  .select,
  .select-multiple {
    max-width: 352px;
    margin-right: 16px;
  }
}
