.range-field {
  width: 100%;

  .array-field {
    &__items {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__inputs {
    display: flex;
  }
}
