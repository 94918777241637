.system-item {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__delete-button {
    margin-left: auto;
  }

  &__button-wrapper {
    margin-bottom: 32px;
    display: flex;
    width: 100%;
    height: 24px;
    min-height: 48px;
  }

  &__loader-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    z-index: 10;
  }

  &__title {
    @include heading-1;
    color: $black;
  }

  &__content {
    width: 100%;
    height: 100%;
  }
}
