.equipment-tree {
  width: 100%;
  height: 100%;

  &__node-container {
    width: 100%;
    display: flex;
    align-items: center;
  }

  &__node-expand-title {
    @include text-4;
    color: $black;
  }

  .ant-tree-title {
    @include text-4;
    color: $black;
  }

  &__node-title {
    @include table-body-text-0;
    color: $black;
  }

  &__node-tools-wrapper {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  &__node-tool-name {
    @include input-title-0;
    color: rgba($black, 0.6);
    margin-right: 16px;
  }

  &__root {
    border: 1px solid rgba($black, 0.1);
    border-radius: 8px;

    .ant-tree-list {
      .ant-tree-switcher {
        width: 22px;
        height: 22px;
        margin-right: 16px;
        display: flex;
        justify-content: center;
        position: relative;
        top: -1px;

        svg {
          width: 100%;
          height: 100%;
          transition-duration: 0.3s;
          transition-property: transform;
          transform: rotate(-90deg);
        }
      }

      .ant-tree-switcher_open {
        svg {
          transform: rotate(0);
        }
      }

      .ant-tree-switcher-noop {
        display: none;
      }

      .ant-tree-treenode {
        width: 100% !important;
        padding: 21px 20px;
        border-bottom: 1px solid rgba($black, 0.1);
        display: flex !important;
        align-items: center !important;

        &:last-child {
          border-bottom: none;
        }
      }

      .ant-tree-node-content-wrapper {
        display: flex;
        align-items: center;
        padding: 0;
        width: 100%;
        min-height: 22px;
        cursor: initial;

        &:hover {
          background: none;
        }

        .ant-tree-title {
          width: 100%;
        }
      }

      .ant-tree-indent {
        .ant-tree-indent-unit {
          width: 38px;
        }
      }

      .filter-node {
        .ant-tree-iconEle {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 24px;
          height: 24px;
          margin-right: 16px;

          svg {
            width: 24px;
            height: 24px;
            fill: $blue;
          }
        }
      }
    }
  }

  &__error-text {
    max-width: 438px;
    text-align: center;
  }

  &__error {
    height: auto;
    padding: 0;
  }

  &__error-icon {
    svg {
      fill: none;
    }
  }

  &__error-content {
    margin-top: 8px;
  }
}
