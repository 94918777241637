.disabling-payment-item {
  display: flex;
  padding-bottom: 28px;
  border-bottom: 1px solid $gray-light-30;
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
    border: none;
    padding: 0;
  }

  &__tariff {
    width: 200px;
    margin-right: 24px;
  }

  &__switch {
    margin-left: 24px;
    margin-top: 33px;
  }

  &__date-picker {
    min-width: 324px;
    margin-right: 24px;

    .date-picker__input-field-icon-container,
    .date-picker__input-filed-icon {
      display: none;
    }

    .date-picker__input-filed-text {
      color: $gray-medium;
    }

    .date-picker__content {
      pointer-events: none;
    }

    &-status {
      margin-top: 64px;
      @include text-0;

      &_active {
        color: $gray-medium;
        padding: 5px 8px;
        border-radius: 8px;
        background-color: rgba($green, 0.2);
        color: $green;
        max-width: 75px;
        font-size: 12px;
      }

      &_not-active-yet {
      }

      &_inactive {
        color: $gray-medium;
      }
    }
  }

  &__comment {
    width: 100%;

    &-status {
      margin-top: 16px;
      @include text-1;

      &_active {
        @include text-0;
      }

      &_inactive {
        color: $gray-medium;
        @include text-0;
      }
    }
  }
}
