.feedback-priority {
  display: flex;
  align-items: center;

  &__label {
    @include text-1;
  }

  &__icon {
    transform: translate(-1px, 2px);

    svg {
      width: 20px;
      height: 20px;
    }
  }
}
