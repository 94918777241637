.simple-tabs {
  position: relative;

  &__scroll-container {
    display: flex;
    width: 100%;
    padding-bottom: 7px;
    overflow: scroll;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;

    .simple-tabs__item {
      margin-bottom: 16px;
    }
  }

  &__item {
    display: flex;
    background-color: $blue-light;
    height: 40px;
    padding: 8px 16px;
    align-items: center;
    border-radius: 12px;
    margin-right: 12px;
    margin-right: 10px;

    &_disabled {
      background-color: $gray-light-50;
      cursor: default
    }

    &-label {
      @include text-1;
      white-space: nowrap;
    }

    &-icon {
      transform: scale(0.6);
      cursor: pointer;
    }
  }

  .tabs__tabs-button {
    &_left {
      top: -2px;
      transform: none;
    }

    &_right {
      top: -2px;
      left: auto;
      right: 0px;
      transform: none;
    }
  }
}
