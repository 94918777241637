.input-search {
  width: 100%;
  position: relative;
  height: fit-content;

  &__input {
    padding: 13.5px 16px 13.5px 48px;
    @include input-text-0;
    border-radius: 8px;
    color: $black;
    background: $white;
    border: 1px solid $gray-light-30;

    &_default-disabled {
      color: $gray-500;
      background: $gray-light-10;
      cursor: auto;
      border: 1px solid $gray-light-30;
    }

    &_clear-enabled {
      padding-right: 56px;
    }

    &:disabled {
      color: $black;
      background: $white;
      cursor: auto;
      border: 1px solid $gray-light-30;
    }

    &:hover {
      border-color: $gray-light-30;
    }

    &:focus {
      box-shadow: none !important;
      border-color: $blue;
    }
  }

  &__icon-wrapper {
    position: absolute;
    top: 0;
    left: 16px;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon-clear-wrapper {
    position: absolute;
    top: 0;
    right: 16px;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transform: scale(0.8);

    svg {
      width: 24px;
      height: 24px;
      fill: $black;
    }

    &:hover {
      svg {
        fill: $blue;
      }
    }
  }
}
