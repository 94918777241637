.contacts-for-subscribers {
  &__title {
    display: flex;
    margin-bottom: 5px;

    &-label {
      color: $gray-medium;
      @include text-1;
    }

    .ant-btn {
      margin-left: auto;
    }
  }

  &__building {
    display: flex;
    position: relative;
    border-bottom: 1px solid $gray-light-30;
    margin-top: 24px;

    &-index {
      @include text-4;
      font-weight: 700;
      margin-right: 20px;
      padding-top: 14px;
    }

    &-content {
      width: 100%;
    }

    &-select {
      margin-bottom: 24px;
      width: 50%;
    }

    &-inputs {
      margin-bottom: 24px;
      display: flex;

      .input,
      .select,
      .ant-btn {
        width: 100%;
        margin-right: 24px;
      }

      .ant-btn {
        margin-top: auto;
        justify-content: center;
        max-width: 165px;
      }
    }
  }

  .ant-btn {
    height: 48px;
  }
}
