.feedback-score {
  display: flex;
  flex-direction: row;

  &__item {
    margin-right: 6px;

    &:last-child {
      margin-right: 0;
    }
  }
}
