.information-block {
  padding: 16px;
  border: 1px solid $gray-light-30;
  border-radius: 8px;
  overflow: hidden;

  &__content {
    display: flex;
  }

  &__col {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    width: 100%;

    &:last-child {
      margin-right: 0;
    }
  }

  &__section {
    margin-bottom: 32px;

    &-title {
      @include text-1;
      white-space: nowrap;
      margin-bottom: 16px;
    }

    &-item {
      display: flex;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0px;
      }

      &-value {
        @include text-1;
        width: 100%;
      }

      &-name {
        min-width: 244px;
        white-space: nowrap;
        @include text-0;
        color: $gray-medium;
        margin: auto;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__children {
    margin-top: 16px;
  }
}
