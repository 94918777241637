.header {
  width: 100%;
  background: $white;
  padding: 18px 24px;
  box-shadow: 0 1px 0 $gray-light-20;

  &__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__title {
    @include heading-0;
    color: $black;
    margin-right: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__nav {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__avatar {
    width: 46px;
    height: 46px;
    background: none;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
