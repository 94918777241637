.dispatcher-info-item {
  &__title {
    display: flex;
    border-bottom: 1px solid $gray-light-30;
    padding: 12px;
    cursor: pointer;

    &:hover {
      .dispatcher-info-item__title-plan {
        opacity: 1;
      }
    }

    &-plan {
      margin-left: auto;
      opacity: 0;
      transform: translate(0, 10px);
      @include transition-opacity-standard();
    }

    &-arrow {
      display: flex;
      align-items: center;
      margin-right: 8px;
      transform: rotate(-90deg);
      @include transition-standard();

      &_open {
        transform: rotate(0);
      }
    }

    &-label {
      @include text-1;
      display: flex;
      align-items: center;
      padding-left: 16px;
      font-weight: 700;
    }
  }

  &__list {
    border-bottom: 1px solid $gray-light-30;

    &-item {
      padding: 8px 0 8px 60px;
      cursor: pointer;
      position: relative;

      &::before {
        position: absolute;
        transform: translate(-17px, 4px);
      }

      &:hover {
        background-color: $gray-light-10;
      }
    }
  }
}
