.mailing-date-modal {
  &__button-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__wrapper {
    .ant-modal-header {
      padding-bottom: 0;
      border-radius: 20px;
    }

    .ant-modal-content {
      overflow: visible;
    }

    .ant-modal-title {
      min-height: 32px;
      padding: 0;
    }

    .ant-modal-body {
      padding-top: 16px;

      .input {
        margin-bottom: 24px;
      }
    }

    .ant-modal-footer {
      padding-top: 16px;
    }
  }
}
