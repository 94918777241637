.loader {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    height: 60px;
    width: 60px;
    vertical-align: middle;
    transform-origin: center;
    animation: loader-ring-rotate 2s linear infinite;

    circle {
      fill: none;
      stroke: $black;
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      stroke-linecap: round;
      animation: loader-ring-stretch calc(2s * 0.75) ease-in-out infinite;
    }

    &_gray {
      circle {
        stroke: $gray-medium;
      }
    }

    &_blue {
      circle {
        stroke: $blue;
      }
    }

    &_black {
      circle {
        stroke: $black;
      }
    }

    &_white {
      circle {
        stroke: $white;
      }
    }

    &_red {
      circle {
        stroke: $red;
      }
    }

    &_green {
      circle {
        stroke: $green;
      }
    }

    &_yellow {
      circle {
        stroke: $yellow;
      }
    }
  }
}

@keyframes loader-ring-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-ring-stretch {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dashoffset: -124px;
  }
}
