.role-users-tab {
  margin-top: 16px;

  &__header-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg:hover {
      path {
        stroke: $blue;
      }
    }
  }

  .ant-table-wrapper {
    max-width: 800px;
  }

  .editable-table {
    &__data-container,
    &__icon-container {
      padding: 18px 12px;
      height: 55px;
      min-height: auto;
      @include text-1;
      min-height: 55px;
      height: auto;
    }

    &__icon {
      svg:hover {
        path {
          stroke: $red;
        }
      }
    }
  }

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    border-right: 1px solid $gray-light-20;

    &:last-child {
      border-right: none;
    }
  }

  .ant-table-thead > tr > th {
    padding: 14px 12px;
    height: 56px;
  }

  .ant-table-tbody > tr > td:first-child {
    color: $blue;
  }

  .ant-table-cell::before {
    display: none;
  }

  &__fio {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 400px;
    cursor: pointer;

    p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
