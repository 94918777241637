.notify {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  max-height: calc(100vh - 60px);
  width: 500px !important;
  padding: 10px 0 0 0 !important;
  top: 60px !important;
  right: 34px !important;

  .Toastify__toast-body {
    padding: 0;
    margin: 0;
    overflow: visible;

    & > div:last-child {
      display: flex;
      flex-direction: column;
      overflow: visible;
    }
  }
}

@keyframes swirl-in-fwd {
  0% {
    transform: translate(0, -200px);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

.swirl-in-fwd {
  animation: swirl-in-fwd 0.2s ease-out both;
}

@keyframes swirl-out-bck {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(0, -200px);
    opacity: 0;
  }
}

.swirl-out-bck {
  animation: swirl-out-bck 0.2s ease-in both;
}
