.subscribers-base-tab {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__approval {
    display: flex;
    margin-top: 16px;

    &-item {
      @include text-0;
      margin-right: 32px;
      display: flex;

      svg {
        margin-right: 4px;
      }
    }
  }

  &__content-block {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &:first-child {
      margin-top: 0;
    }
  }

  &__title-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
    row-gap: 24px;
  }

  &__title-buttons {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  &__button {
    width: 212px;
    padding: 13.5px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    @include text-4;
    color: $black;
    display: flex;
    align-items: center;
  }

  &__text {
    @include table-body-text-0;
    color: $gray-medium;
  }

  &__content-wrapper {
    width: 100%;
    margin-top: 24px;
  }

  &__address-content {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__content-wrapper-grid {
    width: 100%;
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    column-gap: 24px;
  }

  &__button-next {
    margin-left: auto;
  }

  &__author-wrapper {
    margin-top: 23px;
    width: 100%;
    border: 1px solid $gray-light-30;
    border-radius: 8px;
    padding: 8px 16px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto;
    column-gap: 24px;
    row-gap: 16px;
    @include text-2;
    color: $black;
  }

  &__author-field {
    height: auto;
    white-space: nowrap;
    color: rgba($black, 0.6);
  }

  &__author-data {
    display: flex;
    align-items: flex-start;
  }

  &__notify-main-subscriber {
    &-text {
      @include text-1;
      margin-bottom: 24px;
      color: $gray-medium;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
