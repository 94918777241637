.filters-tool {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;

  &__row {
    display: flex;
    align-items: flex-end;
    margin-bottom: 32px;
    width: 100%;

    &_hide {
      display: none;
    }
  }

  &__item {
    margin-left: 24px;
    flex: 1;

    &_mb {
      margin-bottom: auto;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  &__select {
    width: 100%;
  }

  &__search {
    width: 100%;
  }

  &__date {
    width: 100%;
  }

  &__multi-select {
    width: 100%;
  }

  &__button {
    justify-content: center;
    align-items: center;
    height: 48px;
    max-width: fit-content;
  }
}
