.organization-info {
  &__select-org {
    margin-bottom: 32px;
    max-width: 456px;
  }

  .loader {
    margin: auto;
  }
}
