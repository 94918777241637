.system-schema-tab {
  width: 100%;
  height: calc(100vh - 314px);
  display: grid;
  grid-template-columns: $tree-width calc(100% - #{$tree-width} - 24px);
  grid-template-rows: 100%;
  column-gap: 24px;
  background-color: $white;

  &__download-button {
    position: fixed;
    top: 100px;
    right: 24px;
    z-index: 10;
  }

  &_fullscreen {
    position: fixed;
    width: 100vw;
    height: 100vh;
    padding: 16px 16px 82px 16px;
    left: 0;
    top: 0;
    background-color: $white;
    z-index: 11;

    .system-schema-tab__download-button {
      top: 16px;
    }

    .tab-nav-buttons {
      padding-left: 16px;
    }
  }

  &__tab-buttons-full-screen {
    left: 0;
    background: none;
  }

  &__schema {
    width: 100%;
    height: calc(100% - #{$schema-buttons-height});
    margin-top: 106px;
  }

  &__left-block {
    width: 100%;
    height: 100%;
  }

  &__right-block {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__schema-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.schema-modal {
  &__wrapper {
    .ant-modal-content {
      overflow: visible;
    }

    .ant-modal-header {
      padding-bottom: 16px;
      border-radius: 20px;
    }
  }

  &__parent {
    @include text-1;
    font-weight: 500;
    margin-bottom: 16px;
  }

  &__input-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;

    .input {
      width: 100%;
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
