.file-uploader {
  &__download {
    max-height: 120px;

    &.ant-upload.ant-upload-drag {
      background-color: $white;

      .ant-upload {
        padding: 0;
      }

      .ant-upload-drag-container {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    &-message {
      width: 288px;
      @include text-0;
      color: $gray-500;
      text-align: center;
      margin-top: 22px;
    }

    &-button {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-top: 11px;
      margin-bottom: 37px;
      @include text-0;
      font-weight: 500;
      color: $blue;
    }
  }

  &__info {
    @include text-0;
    color: $gray-medium;
    font-size: 12px;
    text-align: center;
    margin-top: 16px;
  }

  .ant-upload-list {
    display: none;
  }

  &__error {
    color: $red;
    margin-top: 6px;
    text-align: center;
    font-size: 12px;
    @include text-0;
    font-size: 12px;
  }

  &__list {
    margin-top: 24px;

    .file-uploader__img {
      margin-bottom: 16px;
    }
  }

  &__img {
    display: flex;
    width: 100%;

    &-content {
      hyphens: auto;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &-preview {
      height: 40px;
      min-width: 40px;
      max-width: 40px;
      margin-right: 12px;

      img,
      video {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 100%;
      }
    }

    &-name {
      color: $blue;
      margin-right: 5px;
      @include text-1;
      font-weight: 500;
      margin-bottom: 4px;
    }

    &-size {
      color: $gray-500;
      margin-right: 13px;
      white-space: nowrap;
      @include tooltip-text-0;
      font-weight: 500;
    }

    &-icon {
      cursor: pointer;
      position: relative;
      top: -8px;
      margin-left: auto;

      svg {
        transform: scale(0.5);

        path {
          stroke: $gray-800;
          opacity: 0.5;
        }
      }
    }
  }
}
