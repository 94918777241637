.object-place-mark {
  &__pie-chart {
    cursor: pointer;
    opacity: 0.7;
    @include transition-opacity-standard();

    &_active {
      opacity: 1;
    }

    &_inactive {
      opacity: 0.5;
    }

    &:hover {
      opacity: 1;
    }
  }
}
