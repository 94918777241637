.add-flats-modal {
  display: flex;
  flex-direction: column;

  &__checkbox-list {
    margin-top: 16px;

    .ant-checkbox-wrapper {
      margin-left: 0;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__button-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__field-wrapper {
    margin-top: 24px;
    display: flex;
    flex-direction: column;

    &:first-child {
      margin-top: 0;
    }
  }

  &__title {
    @include input-title-0;
    color: $gray-medium;
    margin-bottom: 6px;
  }

  &__error {
    @include input-error-text-0;
    color: $red;
    margin-top: 6px;
  }

  &__input-wrapper {
    display: flex;
    align-items: flex-start;
  }

  &__input {
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }
  }

  .single-date-picker {
    margin-top: 24px;
    max-width: 100%;
  }
}
