.help-faq {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__chapters {
    width: 100%;
    margin-top: 32px;
  }

  &__select-wrapper {
    max-width: 330px;
  }

  &__loader-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px;
  }

  &__search-error-wrapper {
    width: 100%;
    height: 100%;
    padding: 32px !important;
  }
}
