.plan-item-page {
  position: relative;

  &__button-wrapper {
    width: 100%;
    margin-bottom: 20px;
    width: 100%;
  }

  .system-schema-tab {
    height: calc(100vh - 240px);
  }

  .system-schema-tab_fullscreen {
    height: 100vh;
  }

  .schema-buttons__content {
    align-items: flex-end;
    margin-bottom: 10px;
    overflow: visible;
  }

  .system-schema-tab__schema {
    height: calc(100% - 90px);
    margin-top: 80px;
  }

  .ant-tree-title-name.dispatcher-panel__status {
    &::before {
      transform: translate(-8px, -1px);
    }
  }
}
