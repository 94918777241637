.tariffs {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__notify {
    color: $gray-medium;
    @include text-1;

    &_default {
      margin-bottom: 32px;
    }

    &_changes {
      margin-bottom: 16px;
    }
  }

  &__deffer-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;

    &-label {
      @include text-4;
    }
  }

  &__deffer-new-func {
    background-color: $blue-light;
    padding: 12px 20px;
    @include text-1;
    margin-bottom: 32px;
    border-radius: 8px;
    border-left: 8px solid $blue;

    span {
      color: $blue;
      margin-right: 5px;
    }
  }

  .ant-tabs-extra-content {
    .ant-btn {
      height: 44px;
    }
  }

  &__buttons {
    display: flex;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    background-color: $white;
    padding: 16px 24px;

    .ant-btn {
      width: 212px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
    }

    .button__tertiary {
      margin-right: auto;
    }

    .ant-checkbox-wrapper {
      margin: 0 25px;

      &-disabled {
        span {
          color: $gray-medium;
        }
      }
    }
  }

  .single-date-picker {
    width: 240px;

    &__input-field {
      width: 240px;
    }
  }
}
