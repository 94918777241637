.tariff-periods {
  &__title {
    display: flex;
    align-items: center;
    margin-top: 41px;

    &-label {
      @include help-header-0;
    }

    &-buttons {
      display: flex;
      margin-top: auto;
      margin-left: auto;
  
      &-sort {
        height: 48px;
        width: 48px;
        display: flex;
        padding: 0;
  
        svg {
          margin: auto;
        }
      }
  
      .ant-btn {
        height: 48px;
        margin-right: 15px;
      }
  
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__item {
    margin-top: 48px;
    padding-bottom: 24px;
    border-bottom: 1px solid $gray-300;
    position: relative;

    &-options {
      display: flex;
      margin-bottom: 24px;

      .select {
        width: 236px;
        margin-right: 24px;
      }

      .input {
        width: 236px;
      }

      .ant-btn {
        height: 48px;
        margin: 21px 0 auto 24px;
      }
    }

    &-costs {
      display: flex;
      align-items: end;

      .input {
        width: 370px;
        margin-right: 24px;
      }
    }
  }
}
