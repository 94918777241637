.default-scrollbar-override {
  .rc-virtual-list-scrollbar {
    width: 4px !important;
    background: $gray-light-30 !important;

    .rc-virtual-list-scrollbar-thumb {
      background: $gray-medium !important;
    }
  }

  &:hover, &:focus {
    &::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background: none;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $gray-medium;
    visibility: hidden;
    transition: 0.3s all linear;
  }
}
