.flat-subscribers-tab {
  .ant-table-cell {
    &::before {
      display: none;
    }
  }

  &__info {
    margin-bottom: 32px;
  }

  &__fio {
    cursor: pointer;
    color: $blue;
  }

  &__tariff {
    color: $blue;
    cursor: pointer;
  }

  &__status {
    height: 8px;
    width: 8px;
    border-radius: 100%;
    margin-right: 15px;
    transform: translate(0px, -2px);

    &_active {
      background-color: $green;
    }

    &_inactive {
      background-color: $red;
    }
  }

  &__radio {
    .ant-radio {
      &::after {
        width: 20px;
        height: 20px;
        margin-top: -10px;
        margin-left: -10px;
      }

      &-inner {
        width: 20px;
        height: 20px;
      }
    }
  }

  .editable-table {
    &__data-container {
      min-height: auto;
      max-height: 59px;
    }

    &__icon {
      &-container {
        min-height: auto;
        max-height: 59px;
      }

      svg {
        &:hover {
          path {
            stroke: $red;
          }
        }
      }
    }
  }
}
