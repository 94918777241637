.disabling-push {
  position: relative;

  &__buttons {
    display: flex;
    margin-bottom: 10px;
    width: 100%;

    &-push {
      margin-left: auto;
      height: 48px;

      span {
        @include text-1;
      }

      .button__icon {
        &_left,
        &_right {
          width: 24px;
          height: 24px;
        }

        &_left {
          opacity: 0.3;
        }

        &_right {
          @include transition-standard();

          svg {
            path {
              stroke: $blue;
            }
          }
        }
      }

      &_active {
        .button__icon_left {
          opacity: 1;
        }
      }

      &_open {
        background-color: $blue-light !important;

        .button__icon_right {
          transform: rotate(180deg);
        }
      }

      &:hover {
        border: 1px solid rgba($blue, 0.5) !important;
      }
    }
  }

  &__content {
    padding: 20px;
    border: 1px solid rgba($blue, 0.5);
    background-color: $blue-light;
    border-radius: 8px;
    border-top-right-radius: 0;
  }

  &__pseudo-block {
    position: absolute;
    height: 20px;
    border: 1px solid rgba($blue, 0.5);
    background-color: $blue-light;
    width: 320px;
    top: 39px;
    right: 0px;
    border-top: 0px;
    border-bottom: 0px;
  }
}
