.change-priority {
  &__button-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__wrapper {
    .file-uploader {
      margin-top: 24px;
    }

    .ant-modal-header {
      border-radius: 20px;
    }

   .ant-modal-content {
      overflow: visible !important;
    }

    .select {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
