.device-notifications-list {
  width: 100%;
  height: 100%;
  flex: 1;
  padding-right: 10px;
  overflow-y: scroll;
  height: calc(100vh - 420px);

  &__empty {
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include text-0;
    color: $gray-medium;
  }

  &__item {
    padding: 12px;

    &-dates {
      display: flex;
      justify-content: space-between;

      &-date {
        @include text-0;
        font-size: 12px;
        color: $gray-medium;
        margin-bottom: 12px;
      }
    }

    &_unresolved {
      background-color: $blue-light;
      border-left: 4px solid $blue;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &-title {
      display: flex;
      justify-content: space-between;
      color: $gray-medium;
      @include text-0;
    }

    &-date-picker {
      width: 100%;
      margin-right: 16px;

      .single-date-picker__content {
        height: 40px;
      }

      .single-date-picker__input-field {
        @include text-1;
      }
    }

    &-date {
      display: flex;
      max-height: 40px;
    }

    &-text {
      @include text-1;
      padding: 10px 12px 10px 0;
    }
  }

  &__line {
    background-color: $gray-light-30;
    height: 1px;
    width: 100%;
    margin: 12px 0;
  }
}
