.scrollbar {
  height: 100%;

  .os-viewport {
    position: static;
  }

  .os-scrollbar-vertical {
    padding: 16px 10px 16px 0;

    .os-scrollbar-track {
      width: 4px;
      border-radius: 10px;
      overflow: hidden;
      background: $gray-light-30;

      .os-scrollbar-handle {
        border-radius: 10px;
        background: $gray-medium;
      }
    }
  }

  .os-scrollbar-horizontal {
    padding: 0 0 4px 0;

    .os-scrollbar-track {
      height: 4px;
      border-radius: 10px;
      overflow: hidden;
      background: $gray-light-30;

      .os-scrollbar-handle {
        border-radius: 10px;
        background: $gray-medium;
      }
    }
  }

  &_disable-indent {
    .os-scrollbar-vertical {
      padding: 0 4px 0 0;
    }

    .os-scrollbar-horizontal {
      padding: 0 0 4px 0;
    }
  }
}
