.pie-chart {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: 100%;
  cursor: default;

  &__number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    border-radius: 100%;
    background-color: white;
    @include text-1;
    font-weight: 700;
  }
}
