.ask-help-question {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  border: 1px solid $gray-light-30;
  border-radius: 8px;
  background: linear-gradient(89.93deg, $blue-light 0.07%, rgba(239, 243, 255, 0) 119.16%), url('/assets/img/askHelpQuestionBackground.jpg');
  background-repeat: no-repeat;
  background-position: right center;
  background-size: contain;

  &__title {
    width: 100%;
    @include help-header-0;
    color: $black;
  }

  &__sub-title {
    width: 100%;
    margin-top: 8px;
    @include input-text-0;
    color: $black;
  }

  &__content {
    width: 100%;
    margin-top: 24px;
    display: flex;
    align-items: flex-end;
  }

  &__select-wrapper {
    width: 100%;
    max-width: 330px;
    margin-right: 24px;
  }

  &__input-wrapper {
    width: 100%;
    margin-right: 24px;
  }

  &__button-wrapper {
    background: $white;
    border-radius: 8px;
    min-width: 212px;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 212px;
    border-color: transparent;
  }
}
