.layout__content-wrapper_sidebar-expanded .tab-nav-buttons {
  padding-left: $sidebar-expanded-width + 23px;
}

.tab-nav-buttons {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  background: $white;
  z-index: 11;
  @include transition-standard;
  padding-left: $sidebar-width + 10px;

  &__button {
    width: 212px;
    justify-content: center;
    align-items: center;
  }

  &__button-cancel {
    margin-right: auto;
  }

  &__button-save {
    margin-left: auto;
  }

  &__button-next {
    margin-left: 16px;
  }

  &:last-child {
    margin-right: 0;
  }
}
