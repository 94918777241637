@mixin heading-0 {
  font-family: $helvetica-neue;
  font-style: normal;
  font-weight: 700;
  font-size: $heading-0-font-size;
  line-height: $heading-0-line-height;
}

@mixin heading-1 {
  font-family: $sf-pro-display;
  font-style: normal;
  font-weight: 600;
  font-size: $heading-1-font-size;
  line-height: $heading-1-line-height;
}

@mixin heading-2 {
  font-family: $sf-pro-display;
  font-style: normal;
  font-weight: 500;
  font-size: $heading-2-font-size;
  line-height: $heading-2-line-height;
}

@mixin heading-3 {
  font-family: $sf-pro-display;
  font-style: normal;
  font-weight: 600;
  font-size: $heading-3-font-size;
  line-height: $heading-3-line-height;
}

@mixin heading-4 {
  font-family: $helvetica-neue;
  font-style: normal;
  font-weight: 700;
  font-size: $heading-4-font-size;
  line-height: $heading-4-line-height;
}

@mixin text-0 {
  font-family: $helvetica-neue;
  font-style: normal;
  font-weight: 400;
  font-size: $text-0-font-size;
  line-height: $text-0-line-height;
}

@mixin text-1 {
  font-family: $helvetica-neue;
  font-style: normal;
  font-weight: 400;
  font-size: $text-1-font-size;
  line-height: $text-1-line-height;

  font-feature-settings: 'pnum' on, 'lnum' on;
}

@mixin text-2 {
  font-family: $helvetica-neue;
  font-style: normal;
  font-weight: 400;
  font-size: $text-2-font-size;
  line-height: $text-2-line-height;
}

@mixin text-3 {
  font-family: $helvetica-neue;
  font-style: normal;
  font-weight: 400;
  font-size: $text-3-font-size;
  line-height: $text-3-line-height;
}

@mixin text-4 {
  font-family: $helvetica-neue;
  font-style: normal;
  font-weight: 700;
  font-size: $text-4-font-size;
  line-height: $text-4-line-height;
}

@mixin error-text-0 {
  font-family: $helvetica-neue;
  font-style: normal;
  font-weight: 400;
  font-size: $error-text-0-font-size;
  line-height: $error-text-0-line-height;
}

@mixin tab-title-0 {
  font-family: $helvetica-neue;
  font-style: normal;
  font-weight: 500;
  font-size: $tab-title-0-font-size;
  line-height: $tab-title-0-line-height;
}

@mixin input-title-0 {
  font-family: $helvetica-neue;
  font-style: normal;
  font-weight: 400;
  font-size: $input-title-0-font-size;
  line-height: $input-title-0-line-height;
}

@mixin input-error-text-0 {
  font-family: $helvetica-neue;
  font-style: normal;
  font-weight: 400;
  font-size: $input-error-text-0-font-size;
  line-height: $input-error-text-0-line-height;
}

@mixin input-text-0 {
  font-family: $helvetica-neue;
  font-style: normal;
  font-weight: 400;
  font-size: $input-text-0-font-size;
  line-height: $input-text-0-line-height;
}

@mixin button-text-0 {
  font-family: $helvetica-neue;
  font-style: normal;
  font-weight: 400;
  font-size: $button-text-0-font-size;
  line-height: $button-text-0-line-height;
}

@mixin button-text-1 {
  font-family: $helvetica-neue;
  font-style: normal;
  font-weight: 400;
  font-size: $button-text-1-font-size;
  line-height: $button-text-1-line-height;
}

@mixin link-button-text-0 {
  font-family: $sf-pro-display;
  font-style: normal;
  font-weight: 500;
  font-size: $link-button-text-0-font-size;
  line-height: $link-button-text-0-line-height;
}

@mixin tooltip-text-0 {
  font-family: $helvetica-neue;
  font-style: normal;
  font-weight: 400;
  font-size: $tooltip-text-0-font-size;
  line-height: $tooltip-text-0-line-height;
}

@mixin table-header-text-0 {
  font-family: $helvetica-neue;
  font-style: normal;
  font-weight: 700;
  font-size: $table-header-text-0-font-size;
  line-height: $table-header-text-0-line-height;
}

@mixin table-body-text-0 {
  font-family: $helvetica-neue;
  font-style: normal;
  font-weight: 400;
  font-size: $table-body-text-0-font-size;
  line-height: $table-body-text-0-line-height;
}

@mixin help-header-0 {
  font-family: $helvetica-neue;
  font-style: normal;
  font-weight: 700;
  font-size: $help-header-0-font-size;
  line-height: $help-header-0-line-height;
}

@mixin help-title-0 {
  font-family: $helvetica-neue;
  font-style: normal;
  font-weight: 700;
  font-size: $help-title-0-font-size;
  line-height: $help-title-0-line-height;
}
