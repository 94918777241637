.faq-question {
  width: 100%;
  border: 1px solid $gray-light-30;
  border-radius: 8px;
  overflow: hidden;

  &__header {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    padding: 16px 16px 18px 16px;
    background: $white;
    cursor: pointer;

    &_expanded {
      background: $gray-light-50;
    }
  }

  &__title {
    width: 100%;
    @include help-title-0;
    color: $black;
  }

  &__icon {
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;
      fill: $black;
    }
  }

  &__body {
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
  }

  &__text {
    @include input-text-0;
    color: $black;
    text-decoration: none;
    outline: none;

    &:hover {
      color: $black;
    }
  }

  &__link {
    margin-top: 16px;
    @include input-text-0;
    color: $blue;
    text-decoration: none;
    outline: none;

    &:hover {
      color: $blue;
    }
  }
}
