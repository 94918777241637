.payment {
  &__back-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .information-block {
    margin-bottom: 32px;
  
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__loader-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px;
  }

  &__error-wrapper {
    width: 100%;
    height: 100%;
    padding: 32px !important;
  }

  &__content {
    margin-top: 35px;
  }
}
