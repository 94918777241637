.date-picker {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: auto;

  &__error {
    .date-picker__input-field {
      border: 1px solid $red;
    }
  }

  .input__error {
    margin-top: 54px;
  }

  &__title {
    @include input-title-0;
    color: $gray-medium;
    margin-bottom: 6px;
    display: flex;
  }

  &__title-required {
    color: $red;
  }

  &__content {
    position: relative;
    width: 100%;
    height: auto;
  }

  &__title-info-icon {
    width: 16px;
    height: 16px;
    margin-left: 8px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__separator {
    margin: 0 4px;
    @include table-body-text-0;
    color: $black;
  }

  .ant-picker-disabled {
    background: $white;
    border-color: $gray-light-30;
  }

  &__default-disabled {
    .date-picker__input-field {
      color: $gray-500 !important;
      background: $gray-light-10 !important;
      border: 1px solid rgba($black, 0.1) !important;
    }
  }

  &__input-field {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: auto;
    width: 100%;
    padding: 12px 36px 12px 16px;
    border: 1px solid $gray-light-30;
    border-radius: 8px;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    z-index: 1;
    background: $white;
    overflow: hidden;
    min-height: 48px;

    &:hover {
      border-color: $gray-light-30;
    }

    &_focus {
      border-color: $blue;

      &:hover {
        border-color: $blue;
      }
    }

    &_disabled {
      cursor: not-allowed;
    }
  }

  &__input-filed-text {
    min-width: 0;
    overflow: hidden;
    white-space: nowrap;
    @include table-body-text-0;
    color: $black;
    text-overflow: ellipsis;
    @include transition-standard();

    &_placeholder {
      color: $gray-500;
    }
  }

  &__input-field-icon-container {
    position: absolute;
    right: 16px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__input-filed-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__input-filed-icon_clear {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 12px;
    left: 0;
    bottom: 12px;
    background: $white;
    opacity: 0;
    z-index: 1;
    @include transition-opacity-standard();

    &-show {
      opacity: 1;
    }

    &:hover {
      opacity: 1;
    }
  }

  .ant-picker-range-arrow {
    display: none;
    opacity: 0;
  }

  &__field {
    visibility: hidden;
    width: 100%;
    padding: 12px 16px;
    border: 1px solid $gray-light-30;
    border-radius: 8px;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    z-index: 0;

    &:hover {
      border-color: $gray-light-30;
    }

    .ant-picker-suffix {
      margin-left: auto;
    }

    .ant-picker-clear {
      right: 16px;
      width: 16px;
      height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: none;

      svg {
        opacity: 0;
      }

      &:hover {
        background: $white;

        svg {
          opacity: 1;
        }
      }
    }

    .ant-picker-active-bar {
      display: none;
    }

    .ant-picker-input {
      width: auto;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @include table-body-text-0;
      color: $black;

      input {
        width: 100%;
        min-width: 0;
        display: block;

        &:disabled {
          color: $black;
        }
      }
    }

    &_clear {
      .ant-picker-input {
        display: none;
        width: 100%;

        &:first-child {
          display: flex;
        }
      }

      .ant-picker-range-separator {
        display: none;
      }
    }
  }

  .ant-picker-focused {
    border-color: $blue;
  }

  &__footer {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__footer-block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_time {
      align-items: flex-end;
    }
  }

  &__footer-button-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 16px;

    &_indent-top {
      margin-top: 24px;
    }
  }

  &__footer-button {
    padding: 11px 16px;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__footer-time-wrapper {
    width: 100%;
    height: 100%;
    min-width: 238px;
    margin-right: 34px;
    display: flex;
    align-items: flex-end;

    &:last-child {
      margin-right: 0;
    }
  }

  &__footer-time-separator {
    height: 48px;
    margin: 0 4px;
    display: flex;
    align-items: center;
    @include table-body-text-0;
    color: $black;
  }

  &__select {
    width: 100%;
  }

  &__footer-info {
    display: flex;
    align-items: center;
    border: 1px solid rgba($black, 0.1);
    border-radius: 8px;
    overflow: hidden;
    padding: 11px 16px;
    min-width: 214px;
  }

  &__footer-info-icon {
    width: 19px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__footer-info-text {
    margin-left: 8px;
    @include text-2;
    color: $black;
    white-space: nowrap;
  }

  &__dropdown {
    @include text-2;
    font-feature-settings: normal;
    color: $black;

    .ant-picker-panel-container {
      background: $white;
      box-shadow: 0 14px 44px rgba($black, 0.25);
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      padding: 24px;

      &:before {
        content: 'Период';
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 24px;
        @include table-header-text-0;
        color: $black;
      }

      .ant-picker-footer {
        border: none;
        line-height: initial;
        margin-top: 20px;

        .ant-picker-footer-extra {
          padding: 0;
          line-height: initial;
        }
      }

      .ant-picker-panels {
        width: 100%;
        display: flex;
        align-items: flex-start;

        .ant-picker-panel {
          padding-right: 34px;
          padding-bottom: 24px;
          border-bottom: 1px solid rgba($black, 0.1);

          &:last-child {
            padding-right: 0;
          }

          .ant-picker-date-panel,
          .ant-picker-month-panel,
          .ant-picker-year-panel,
          .ant-picker-decade-panel {
            width: auto;

            .ant-picker-header {
              align-items: center;
              border: none;
              padding: 0;

              .ant-picker-header-super-prev-btn,
              .ant-picker-header-super-next-btn {
                width: 24px;
                height: 24px;

                svg {
                  width: 100%;
                  height: 100%;

                  path {
                    stroke: $black;
                    stroke-width: 1.5px;
                  }
                }
              }

              .ant-picker-header-prev-btn,
              .ant-picker-header-next-btn {
                width: 24px;
                height: 24px;

                svg {
                  width: 100%;
                  height: 100%;

                  path {
                    stroke: $black;
                    stroke-width: 1.5px;
                  }
                }
              }

              .ant-picker-header-view {
                line-height: initial;
                height: 100%;

                .ant-picker-month-btn,
                .ant-picker-year-btn {
                  line-height: initial;
                  height: 100%;
                  @include text-2;
                  font-weight: 500;
                }
              }
            }

            .ant-picker-body {
              padding: 0;
              margin-top: 5px;

              .ant-picker-content {
                width: auto;
                vertical-align: middle;

                thead tr {
                  vertical-align: middle;
                  line-height: initial;

                  th {
                    width: 34px;
                    height: 34px;
                    line-height: initial;
                    vertical-align: middle;
                    color: rgba($black, 0.6);
                  }
                }

                tbody tr {
                  vertical-align: middle;
                  line-height: initial;

                  td {
                    width: 34px;
                    height: 34px;
                    line-height: initial;
                    vertical-align: middle;
                  }

                  .ant-picker-cell {
                    vertical-align: middle;
                    padding: 0;
                    color: $gray-600;

                    &:before {
                      height: 100%;
                    }
                  }

                  .ant-picker-cell-inner {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }

                  .ant-picker-cell-in-view {
                    color: $black;
                  }

                  .ant-picker-cell-disabled {
                    color: $gray-600;

                    &::before {
                      background: none;
                    }
                  }

                  .ant-picker-cell-range-hover {
                    &:after,
                    .ant-picker-cell-inner::after {
                      height: 100%;
                      width: 100%;
                      left: 0;
                      right: 0;
                      border-color: $blue;
                    }
                  }

                  .ant-picker-cell-range-start {
                    .ant-picker-cell-inner {
                      border-top-left-radius: 8px;
                      border-bottom-left-radius: 8px;
                    }
                  }

                  .ant-picker-cell-range-hover-start {
                    &:after,
                    .ant-picker-cell-inner::after {
                      height: 100%;
                      width: 100%;
                      left: 0;
                      right: 0;
                      border-color: $blue;
                      border-top-left-radius: 8px;
                      border-bottom-left-radius: 8px;
                    }
                  }

                  .ant-picker-cell-range-end {
                    .ant-picker-cell-inner {
                      border-top-right-radius: 8px;
                      border-bottom-right-radius: 8px;
                    }
                  }

                  .ant-picker-cell-range-hover-end {
                    &:after,
                    .ant-picker-cell-inner::after {
                      height: 100%;
                      width: 100%;
                      right: 0;
                      left: 0;
                      border-color: $blue;
                      border-top-right-radius: 8px;
                      border-bottom-right-radius: 8px;
                    }
                  }

                  .ant-picker-cell-today {
                    .ant-picker-cell-inner::before {
                      border-radius: 8px;
                    }
                  }

                  .ant-picker-cell-in-range::before {
                    background: $gray-200;
                  }
                }
              }
            }
          }

          .ant-picker-month-panel {
            width: 510px;

            .ant-picker-header {
              height: 24px;
            }

            .ant-picker-body {
              margin-top: 10px;

              .ant-picker-content {
                width: 100%;
                height: auto;

                tbody tr {
                  .ant-picker-cell {
                    padding: 8px;
                    height: 49px;

                    #text {
                      z-index: 1;
                    }
                  }

                  .ant-picker-cell-selected,
                  .ant-picker-cell-in-view,
                  .ant-picker-cell-range-hover {
                    &:before {
                      display: none;
                    }
                  }

                  .ant-picker-cell-in-range {
                    background: $gray-light-50;

                    &:before {
                      display: none;
                    }
                  }

                  .ant-picker-cell-range-start,
                  .ant-picker-cell-range-hover-start {
                    background: $blue;
                    border-radius: 8px 0 0 8px;
                    color: $white;

                    &:before {
                      display: none;
                    }
                  }

                  .ant-picker-cell-range-end,
                  .ant-picker-cell-range-hover-end {
                    background: $blue;
                    border-radius: 0 8px 8px 0;
                    color: $white;
                  }
                }
              }
            }
          }

          .ant-picker-year-panel,
          .ant-picker-decade-panel {
            width: 510px;

            .ant-picker-header {
              height: 24px;

              .ant-picker-header-view {
                display: flex;
                align-items: center;
                justify-content: center;
              }

              .ant-picker-decade-btn {
                height: 100%;
                display: flex;
                align-items: center;
                margin: 0 auto;
              }
            }

            .ant-picker-body {
              margin-top: 10px;

              .ant-picker-content {
                width: 100%;
                height: auto;

                tbody tr {
                  .ant-picker-cell-inner {
                    padding: 16px;
                    white-space: nowrap;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
