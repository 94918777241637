.editable-table {
  &__icon-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 12px;
    min-height: 80px;
    height: 100%;
  }

  &__title-icon {
    cursor: pointer;
    transform: translate(0px, 6px);
    width: 60px;
    margin-left: auto;
    margin-right: auto;
    
    .tooltip__wrapper {
      width: 24px;
      height: 24px;
    }

    svg {
      path {
        stroke: $gray-medium;
      }
    }

    &:hover {
      svg {
        path {
          stroke: $blue;
        }
      }
    }
  }

  &__select-container {
    .select__select {
      .ant-select-selector {
        padding: 8px 12px 8px 8px !important;
        min-height: 32px;
      }
      .ant-select-arrow {
        right: 4px;
      }
    }
  }

  &__icon {
    cursor: pointer;
    width: 24px;
    height: 24px;

    svg {
      width: 100%;
      height: 100%;
    }

    &:first-child {
      margin-right: 12px;
    }

    &:hover {
      svg {
        path {
          stroke: $blue;
        }
      }
    }
  }

  &__icon-active {
    svg {
      path {
        stroke: $blue;
      }
    }
  }

  &__data-container {
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 80px;
    height: 100%;
    padding: 20px 12px;

    &_input {
      padding-top: 24px;
      height: auto;
      align-items: flex-start;
      padding-bottom: 0;
    }

    &_disabled {
      opacity: 0.5;

      .ant-checkbox-wrapper {
        cursor: auto;

        .ant-checkbox {
          cursor: auto;

          .ant-checkbox-input {
            cursor: auto;
          }
        }

        &:hover {
          .ant-checkbox-inner {
            border-color: $gray-400;
          }
        }
      }
    }
  }

  .ant-table {
    border: 1px solid $gray-light-40;
    border-radius: 8px;
    overflow: hidden;
  }

  .ant-table-thead > tr > th {
    background: none;
    border-bottom: 1px solid $gray-light-20;
    @include table-header-text-0;
    color: #000000;
    padding: 20px 12px;

    &:before {
      display: none;
    }
  }

  .ant-table-thead > tr {
    .ant-table-selection-column {
      padding: 20px 12px;
      vertical-align: middle;
    }
  }

  .ant-table-tbody > tr {
    > td {
      background: none;
      border-bottom: 1px solid $gray-light-20;
      @include table-body-text-0;
      color: #000000;
      padding: 0;
      vertical-align: top;
    }

    &:last-child > td {
      border-bottom: none;
    }

    .ant-table-selection-column {
      padding: 20px 12px;
      vertical-align: middle;
    }
  }

  .ant-table-container {
    &::before,
    &::after {
      box-shadow: none !important;
    }
  }

  .ant-table-cell-row-hover {
    background: $gray-light-10 !important;
  }

  .ant-table-cell:empty:before {
    content: '-';
  }

  .ant-spin-nested-loading {
    position: static;

    & > div:first-child {
      position: relative;
      width: 100%;
    }

    .ant-spin {
      height: auto;
      top: 100px;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .ant-spin-dot {
        top: 100%;
        left: auto;
        right: auto;
        margin: 0;
      }
    }

    .ant-spin-container {
      position: static !important;
    }
  }

  &__pagination {
    margin: 20px 0 0 0 !important;

    .ant-pagination-prev,
    .ant-pagination-item,
    .ant-pagination-next,
    .ant-pagination-jump-next,
    .ant-pagination-jump-prev {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include table-body-text-0;
      border: 1px solid $gray-light-30;
      border-radius: 8px;
      color: $blue;

      &:hover {
        color: $blue;
        border-color: $blue;
      }

      &:disabled {
        border-color: $gray-light-30;
      }

      a {
        padding: 0;
        color: inherit;
        outline: none;
        text-decoration: none;
      }

      .ant-pagination-item-link,
      .ant-pagination-item-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .ant-pagination-item-ellipsis {
          @include table-body-text-0;
          color: $blue;
          top: 50%;
        }
      }
    }

    .ant-pagination-item-active {
      background: $blue;
      color: $white;
      border-color: $blue;

      &:hover {
        background: $blue;
        color: $white;
        border-color: $blue;
      }
    }
  }

  &__input-container {
    width: 100%;
  }

  &__input {
    width: 100%;
    padding: 6.5px 10px;
  }

  &__input-error {
    font-size: 9px;
    line-height: 10px;
    margin-top: 2px;
  }

  &__row {
    background: $blue-light;
    position: relative;
    z-index: 2;

    .ant-table-cell-row-hover {
      background: $blue-light !important;
    }
  }

  &__row-selected-drop {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &_visible {
      display: block;
    }
  }
}
