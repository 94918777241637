.disabling-push-form {
  display: flex;
  align-items: flex-end;

  .button {
    background-color: inherit;
    margin-left: 0;
    span {
      @include text-1;
    }
  }

  .select,
  .select-multiple {
    margin-right: 16px;
    flex: 1 1 0;
    width: 0;
  }
}
