.section-flats-tools {
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: flex-end;

  &__item {
    min-width: 212px;
    margin-left: 24px;

    &:first-child {
      margin-left: 0;
    }
  }

  &__select {
    width: 100%;
  }

  &__search {
    width: 100%;
  }

  &__button {
    min-width: 212px;
    justify-content: center;
    align-items: center;
    height: 48px;
  }
}
