.flat-item {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .flat-item__long-search {
    &.input-search {
      width: 548px;
    }
  }

  &__button-wrapper {
    margin-bottom: 32px;
  }

  &__loader-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    z-index: 10;
  }

  &__title {
    @include heading-1;
    color: $black;
  }

  &__content {
    width: 100%;
    height: 100%;
  }
}
