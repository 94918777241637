.layout__content-wrapper_sidebar-expanded .faq-table {
  max-width: calc(100vw - 632px);
}

.faq-table {
  width: 100%;
  height: 100%;
  max-width: calc(100vw - 632px + $sidebar-width);

  &_short {
    .faq-table__tbody {
      max-height: calc(100vh - 352px);
    }
  }

  &__table {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 8px;
    border: 1px solid $gray-light-30;
    overflow-x: scroll;

    &_create {
      height: 100%;
    }
  }

  &__thead {
    display: flex;
    width: fit-content;
    min-width: 100%;
  }

  &__separator {
    border-bottom: 1px solid $gray-light-30;
    position: absolute;
    width: calc(100% - 1px);
    top: 47px;
  }

  &__tbody {
    max-height: calc(100vh - 280px);
    overflow-y: scroll;
    overflow-x: hidden;
    width: fit-content;
    min-width: 100%;
  }

  &__tr {
    display: flex;
    flex-direction: row;
    width: 100%;
    user-select: none;

    &_last {
      .faq-table__td {
        border-bottom: none !important;
      }
    }

    &_disabled {
      cursor: default;

      .faq-table__td,
      .faq-table__th {
        background-color: $gray-light-50;
      }
    }

    .faq-table__td {
      border-bottom: 1px solid $gray-light-30;
      position: relative;
    }

    .faq-table__td:nth-child(1),
    .faq-table__th:nth-child(1) {
      min-width: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid $gray-light-30;
    }
    .faq-table__td:nth-child(2),
    .faq-table__th:nth-child(2) {
      min-width: 150px;
      border-right: 1px solid $gray-light-30;
    }
    .faq-table__td:nth-child(3),
    .faq-table__th:nth-child(3) {
      width: 100%;
    }
    .faq-table__td:nth-child(4) {
      min-width: 90px;
      border-left: 1px solid $gray-light-30;
    }

    .faq-table__th:nth-child(4) {
      position: absolute;
      right: 1px;
      border-top-right-radius: 8px;
      min-width: 50px;
      background-color: white;
      padding-left: 3px;
    }
  }

  &__th {
    min-height: 48px;
    @include text-1;
    font-weight: 700;
  }

  &__td {
    min-height: 44px;
    @include text-1;
  }

  &__th,
  &__td {
    padding: 10px 12px;
    background-color: $white;
    display: flex;
    align-items: center;

    &_hide {
      display: none !important;
    }

    &:last-child {
      border-right: 0;
    }
  }

  &__table-icons {
    display: flex;
    justify-content: space-between;
  }

  &__table-icon {
    cursor: pointer;

    &_edit {
      margin-right: 16px;

      &:hover {
        svg {
          path {
            stroke: $blue;
          }
        }
      }
    }

    &_trash {
      &:hover {
        svg {
          path {
            stroke: $red;
          }
        }
      }
    }
  }

  &__plus {
    cursor: pointer;
    display: flex;
    justify-content: center;
  }

  &__close {
    cursor: pointer;
    display: flex;
    justify-content: center;
    transform: scale(0.8);
    position: relative;
    top: -2px;
  }

  .error-placeholder {
    height: 200px;
  }
}
