.disabling-payment-modal {
  &__button-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__select-info {
    margin-top: 4px;
    @include text-1;
    color: $gray-medium;

    svg {
      margin-right: 4px;
      transform: translate(0px, 1px);
    }
  }

  &__wrapper {
    .ant-modal-header {
      padding-bottom: 0;
      background: initial;
    }

    .ant-modal-content {
      overflow: visible;
    }

    .ant-modal-title {
      min-height: 32px;
      padding: 0;
      @include help-header-0;
    }

    .ant-modal-body {
      padding-top: 4px;
    }

    .ant-modal-footer {
      padding-top: 40px;
    }

    &_finish {
      .ant-modal-title {
        @include text-1;
        font-weight: 700;
      }
    }
  }

  &__title {
    @include text-1;
    margin-bottom: 32px;
    color: $gray-medium;
  }

  &__inputs {
    display: flex;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    & > div {
      width: 100%;
    }
  }

  .single-date-picker {
    min-width: 272px;
    margin-right: 24px;
  }

  .input {
    width: 100%;
  }
}
