.tariff-table-record {
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__name {
    @include text-1;
  }

  &__arrow {
    padding: 0 19px;
    position: relative;
    top: -6px;
    @include transition-standard();

    &_up {
      transform: rotate(-180deg);
    }
  }

  &__description {
    color: $gray-medium;
    height: 0;
    opacity: 0;

    &_show {
      height: auto;
      opacity: 1;
    }
  }
}
