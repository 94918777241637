.email-recipients {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &__info {
    margin: 0 auto;
    @include text-1;
    margin-top: 24px;
  }

  &__add-button {
    height: 0;
    margin-left: auto;
    transform: translate(0px, -80px);
  }
}
