.role-page {
  &__back-button-wrapper {
    margin-bottom: 32px;
  }

  &__delete-button {
    width: 212px;
    justify-content: center;
  }
}
