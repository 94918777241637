.schema-pdf-modal {
  height: calc(100vh - 230px);
  overflow-y: scroll;
  overflow-x: hidden;

  &__content {
    margin: 30px 30px 4px 30px;
    padding: 40px 40px 0 40px;
    -webkit-box-shadow: 0px 1px 8px 0px rgba($black, 0.2);
    -moz-box-shadow: 0px 1px 8px 0px rgba($black, 0.2);
    box-shadow: 0px 1px 8px 0px rgba($black, 0.2);
  }

  &__wrapper {
    .ant-modal-body {
      height: calc(100vh - 230px);
    }
  }

  &__link {
    display: none;
  }

  &__loader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
  }

  &__img-block {
    height: 1080px;
    display: flex;
  }

  &__img {
    height: 600px;
    width: fit-content;
    object-fit: contain;
    margin: auto;
  }

  &__title {
    @include heading-1;
  }

  .directory-tree {
    pointer-events: none;
  }
}
