.faq-table-question {
  width: 100%;
  max-width: 600px;

  &_open {
    .faq-table-question__label {
      font-weight: 700;
    }
  }

  &__answer {
    margin-bottom: auto;
    height: auto;

    &-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-left: 18px;
    }

    &-select {
      margin-bottom: 16px;
      max-width: 300px;
    }

    &-text {
      overflow: hidden;
      text-overflow: ellipsis;
      @include text-1;
      margin-bottom: 16px;
    }

    &-date {
      margin-left: auto;
      color: $gray-medium;
      @include text-0;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__status {
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: $green;
    border-radius: 100%;
    transform: translate(0px, -1px);

    &_inactive {
      background-color: $red;
    }
  }

  &__label {
    @include text-1;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 16px;
  }

  &__icon {
    margin-left: auto;
    cursor: pointer;
    position: absolute;
    right: 12px;

    svg {
      transition-duration: 0.3s;
      transition-property: transform;
      transform: rotate(0);

      path {
        stroke: $black;
      }
    }

    &_open {
      svg {
        transform: rotate(-180deg);
      }
    }
  }
}
