.add-appeal-modal {
  &__row {
    display: flex;
    margin-bottom: 24px;

    & > div {
      margin-right: 24px;
      width: 0;
      flex: 1 1 0;
    }
  }

  &__wrapper {
    .ant-modal-body {
      height: 600px;
    }
  }

  &__button-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__flat-select {
    max-width: 94px;
  }
}
