.equipment-tab {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__tab-pane-container {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto;
  }

  &__tab-pane-text {
    max-width: 100px;
    white-space: normal;
  }

  &__button-container {
    margin-top: auto;
  }

  &__button-next {
    margin-left: auto;
  }
}
