.payments-table {
  &__title-light {
    font-size: 14px;
    font-weight: 400;
    white-space: nowrap;
  }

  &__status {
    padding: 8px 16px;
    border-radius: 8px;
    white-space: nowrap;

    &_Pending {
      background-color: rgba($orange, 0.2);
    }

    &_Succeeded {
      background-color: rgba($green, 0.2);
    }

    &_Refunded {
      background-color: rgba($gray-medium, 0.2);
    }

    &_Canceled,
    &_Overdue {
      background-color: rgba($red, 0.2);
    }
  }

  &.editable-table {
    .ant-table-thead > tr > th {
      padding: 12px 12px;
      position: relative;
    }

    .ant-table-column-sorters {
      height: 40px;
    }

    .ant-table-column-sorter {
      position: absolute;
      right: 1px;
      bottom: 31px;
    }
  }
}
