.device-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__title {
    @include help-title-0;
    margin-bottom: 32px;
  }

  &__button-wrapper {
    margin-bottom: 32px;
  }

  &__buttons {
    margin-bottom: 22px;
    width: 100%;

    &-wrapper {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  &__info-button {
    height: 40px;

    span {
      color: $gray-medium;
      @include text-1;
    }

    .button__icon_right {
      @include transition-standard();
      transform: translate(1px, -2px) scale(1.6);

      svg {
        @include transition-standard();

        path {
          stroke: $gray-medium;
        }
      }
    }

    &_close {
      .button__icon {
        transform: translate(1px, 1px) scale(1.6) rotate(180deg);
      }
    }
  }

  &__loader-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    z-index: 10;
  }

  &__content {
    width: 100%;
    height: 100%;
  }
}
