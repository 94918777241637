.dispatcher-map {
  height: calc(100vh - 400px);
  width: 100%;
  position: relative;

  .loader {
    margin: auto;
  }

  &__info-item {
    position: absolute;
    top: 35px;
    z-index: 1;
    width: 330px;
    background-color: $white;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    overflow: hidden;
    border: 1px solid $gray-light-30;

    &-close {
      position: absolute;
      z-index: 2;
      width: 330px;
      height: 36px;
      border-top-right-radius: 8px;
      background-color: white;
      border: 1px solid $gray-light-30;

      svg {
        position: absolute;
        left: auto;
        right: 16px;
        height: 20px;
        width: 20px;
        cursor: pointer;
        top: 7px;

        path {
          stroke: $gray-light-30;
        }
        
        &:hover {
          path {
            stroke: inherit;
          }
        }
      }
    }
  }

  &__map {
    height: 100%;

    .ymaps-2-1-79-map {
      min-height: 100%;
    }
  }
}
