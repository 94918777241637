.dispatcher-item-dropdown {
  &__title {
    padding: 8px 0 8px 60px;
    cursor: pointer;
    position: relative;
    display: flex;

    &::before {
      position: absolute;
      transform: translate(-17px, 5px);
    }

    &:hover {
      background-color: $gray-light-10;
    }

    &-arrow {
      display: flex;
      align-items: center;
      margin-right: 8px;
      transform: rotate(-180deg);
      @include transition-standard();
      margin-left: auto;

      &_open {
        transform: rotate(0);
      }
    }

    &-label {
      @include text-0;
      font-weight: 500;
      display: flex;
      align-items: center;
    }
  }

  &__content {
    border-top: 1px solid $gray-light-30;
    border-bottom: 1px solid $gray-light-30;
  }

  &__item-name {
    @include text-0;
    cursor: pointer;
    padding: 12px 16px 12px 62px;

    &:hover {
      background-color: $gray-light-10;
    }
  }
}
