.hardware-settings {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .tab-nav-buttons__button {
    min-width: 212px;
    width: auto;
  }

  &__scroll {
    overflow-y: scroll;
  }

  &__tree {
    width: 100%;
    height: 100%;

    &-label {
      transform: translate(0px, 4px);
    }

    &-icon {
      margin-right: 8px;
      transform: translate(0px, 5px);
    }
  }

  &__content-wrapper {
    width: 100%;
    height: calc(100vh - 415px);
    display: grid;
    grid-template-columns: $tree-width calc(100% - #{$tree-width} - 10px);
    grid-template-rows: 100%;
    column-gap: 10px;
    overflow: hidden;
    background-color: $white;
    margin-top: 24px;
  }

  .tabs {
    height: 48px;
    margin-top: 6px;
    margin-bottom: 10px;
  }

  &__tabs {
    .ant-tabs-nav-list {
      border-radius: 12px;
    }

    .ant-tabs-content-holder {
      margin-top: 24px;
    }

    .ant-tabs-tab-active {
      border: 1px solid $blue;

      .tabs__tab-title {
        color: inherit;
      }
    }

    .tabs__tab-title {
      color: $black;
    }

    .ant-tabs-nav-list {
      background-color: $gray-light-50;
    }

    .ant-tabs-nav {
      margin-bottom: 0;
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    padding-right: 14px;

    &_empty {
      overflow: hidden;
    }

    .section-flats-tools {
      margin-bottom: 8px;
    }
  }

  &__title {
    @include text-4;
    color: $black;
    margin-bottom: 16px;
  }

  &__loader-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__button-next {
    margin-left: auto;
  }

  &__error {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-title {
      @include text-1;
    }
  }

  &__error-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    width: 44px;
    margin-bottom: 8px;
    border: 1.5px solid $blue;
    border-radius: 50%;
  }

  .input {
    width: 100%;
  }

  &__download {
    @include text-1;
    cursor: pointer;
    color: blue;
    font-weight: 400;
    margin-bottom: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;

    &-label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-icon {
      margin: 0 10px 0 1px;

      svg {
        scale: 1.3;
        transform: translate(2px, 1px);
      }
    }
  }

  .input {
    margin-bottom: auto;
  }

  &__sync-date {
    @include text-1;
    color: $gray-medium;
    margin-bottom: 24px;
  }

  &__tracts {
    display: flex;
    margin: 26px 0;
    height: 24px;

    &-title {
      @include text-1;
      font-weight: 500;
      margin-right: 16px;
      display: flex;
      align-items: center;
    }

    &-icon {
      cursor: pointer;

      svg {
        path {
          stroke: $gray-medium;
        }
      }

      &:hover {
        svg {
          path {
            stroke: $blue;
          }
        }
      }
    }
  }
}
