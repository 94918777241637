.device-notifications-form {
  width: 100%;
  min-height: 100%;
  background-color: $gray-light-10;
  padding: 12px;
  border-radius: 8px;
  flex: 1;

  &__inputs {
    display: flex;
    margin-top: 16px;

    .single-date-picker {
      margin-right: 100px;

      &__input-field {
        min-width: 230px;
        white-space: nowrap;
        @include text-1;
      }
    }

    .button {
      margin-left: auto;
    }
  }
}
