.modal {
  .ant-modal-content {
    background: $white;
    border-radius: 20px;
    overflow: hidden;
  }

  .ant-modal-header {
    padding: 20px 52px 24px 20px;
    border: none;
  }

  .ant-modal-title {
    @include heading-4;
    color: $black;
    padding: 6px 0;
  }

  .ant-modal-body {
    padding: 0 20px;
  }

  .ant-modal-footer {
    padding: 40px 20px 20px 20px;
    border: none;

    &__buttons {
      width: 100%;
      display: flex;
      flex-direction: row-reverse;

      button {
        margin-left: 8px;
        padding: 10px 14px;
      }
    }
  }

  .ant-modal-close {
    width: 32px;
    height: 32px;
    top: 20px;
    right: 20px;

    .ant-modal-close-x {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba($black, 0.1);
      opacity: 0.2;
      border-radius: 45px;

      svg {
        width: 14px;
        height: 16px;
        fill: $black;
      }
    }
  }
}
