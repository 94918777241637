.serial-numbers-modal {
  .ant-modal-header {
    padding-bottom: 7px;
  }

  &__hardwares {
    height: 240px;
    margin-right: 20px;
  }

  &__notification-info {
    @include text-1;
    color: $gray-medium;
    margin-bottom: 32px
  }

  &__hardware-title {
    @include text-1;
    margin-bottom: 16px;
  }

  &__hardware-inputs {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin-bottom: 16px;

    .input {
      flex: 1;
      width: 100%;
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
