.tracts-options {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 32px;

  &_line {
    grid-template-columns: 1fr;
  }

  &__select {
    width: 100%;

    &-content {
      display: flex;
    }

    &-checkboxes {
      display: flex;
      margin-top: 16px;

      .checkbox {
        width: 100%;

        span {
          white-space: nowrap;
        }
      }
    }

    .select {
      width: 100%;
    }
  }

  &__index {
    @include heading-1;
    font-size: 18px;
    margin-top: auto;
    height: 35px;
    margin-right: 16px;
  }
}
