.access-point-modal {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
  max-height: 500px;
  padding-right: 10px;

  &__input-container {
    width: 100%;
    margin-bottom: 24px;
    margin-top: 16px;
  }

  &__camera-icon-container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto;
    column-gap: 24px;
    row-gap: 24px;
    margin-bottom: 24px;

    .input {
      margin-top: auto;
    }
  }

  &__connects {
    margin-top: 24px;

    .select-multiple {
      margin: 16px 0;
    }
  }

  .schema-modal__parent {
    margin-bottom: 0px;
  }

  &__content-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    column-gap: 24px;
    row-gap: 24px;
    margin-top: 40px;

    &:first-child {
      margin-top: 0;
    }
  }

  &__base-info-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    column-gap: 16px;
  }

  &__checkbox-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__device-setting-container {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    row-gap: 24px;

    &_disabled {
      opacity: 0.5;
    }
  }

  &__device-setting-title {
    @include heading-4;
    color: $black;
  }

  &__device-radio-group-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: stretch;
  }

  &__inputs-container {
    padding: 16px;
    background: $gray-light-10;
    border: 1px solid $gray-light-30;
    border-radius: 8px;
  }

  &__radio-item {
    margin: 0 0 16px 0;

    .ant-radio-wrapper-disabled {
      .ant-radio-checked {
        .ant-radio-inner {
          border-color: $blue-disabled;
          &:after {
            background-color: $blue-disabled;
          }
        }
      }

      span {
        color: $black !important;
      }
    }

    &:last-child {
      margin: 0;
    }
  }

  &__icons-title {
    @include input-title-0;
    color: $gray-900;
    margin-bottom: 6px;
    white-space: nowrap;
  }

  &__icons-container {
    display: flex;
  }

  &__icons-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border: 1px solid $gray-light-30;
    border-radius: 8px;
    margin-right: 8px;
    cursor: pointer;

    &_active {
      border-color: $blue;
      box-shadow: 0 0 0 2px rgba($blue, 20%);
      @include transition-standard();
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
