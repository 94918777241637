.faq-question-edit {
  &__selects {
    display: flex;
    align-items: end;
    margin-bottom: 16px;

    .select {
      margin-right: 16px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-bottom: auto;
    width: 100%;

    .select-multiple {
      max-width: 300px;
    }

    .input {
      margin-bottom: 16px;
    }
  }

  .faq-table {
    &__td {
      background-color: $gray-light-10;
      z-index: 12;
      border-bottom: none;
    }

    &__table-icon_trash {
      margin: auto;
    }
  }

  &__admin-notify {
    display: flex;
    align-items: top;

    &-icon {
      margin-right: 10px;
    }
  }
}
