.faq-section {
  border-radius: 8px;
  border: 1px solid $gray-light-30;
  min-width: 330px;
  margin-right: 24px;
  height: fit-content;

  &_short {
    .faq-section__content {
      height: calc(100vh - 347px);
    }
  }

  &__title {
    padding: 12px 12px 12px 48px;
    display: flex;
    border-bottom: 1px solid $gray-light-30;

    &-icon {
      margin-left: auto;
      cursor: pointer;
    }

    &-label {
      @include help-title-0;
    }
  }

  &__content {
    height: calc(100vh - 280px);
    overflow-y: scroll;

    &_empty {
      padding: 10px 0 0 48px;
    }
  }

  &__item {
    display: flex;
    padding: 12px 12px 12px 47px;
    cursor: pointer;
    align-items: center;
    user-select: none;
    position: relative;

    &_disabled {
      background: $gray-light-10;
      cursor: default;
    }

    &_active {
      background: $blue-light;
    }

    &-dad {
      position: absolute;
      left: 16px;
    }

    &-label {
      @include text-1;
    }

    &-icons {
      margin-left: auto;
      display: flex;
    }

    &-icon {
      cursor: pointer;

      &_edit {
        margin-right: 16px;

        &:hover {
          svg {
            path {
              stroke: $blue;
            }
          }
        }
      }

      &_trash {
        &:hover {
          svg {
            path {
              stroke: $red;
            }
          }
        }
      }
    }

    &:hover {
      background-color: $gray-light-10;
    }
  }
}
