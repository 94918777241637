.sidebar-item {
  &__tab {
    width: 100%;
    padding: 16px 43px;
    @include heading-4;
    color: $white;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 500;
    position: relative;

    &-count {
      position: absolute;
      width: 26px;
      height: 26px;
      background-color: $white;
      color: $blue;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      @include text-0;
      font-weight: bold;
      transform: translate(15px, -26px);

      &_99 {
        font-size: 11px;
        padding-left: 1px;
        padding-top: 3px;

        span {
          transform: translate(0px, -3px);
          font-size: 9px;
          font-weight: bold;
        }
      }
    }

    &_selected {
      font-weight: 700;
      background: $blue-dark;

      .sidebar-item__tab-count {
        color: $blue-dark;
      }
    }

    &:hover {
      color: $white;
      background: $blue-dark;

      .sidebar-item__tab-count {
        color: $blue-dark;
      }
    }

    &_expanded {
      padding: 16px 14px 16px 43px;
    }
  }

  &__tab-category {
    @include text-2;
    color: $gray-light-30;

    &_selected {
      font-weight: 400;
      color: $white;
      background: $blue-dark;
    }

    &_expanded {
      padding: 16px 24px 16px 43px;
    }
  }

  &__tab-logo {
    width: 32px;
    min-width: 32px;
    height: 32px;
    min-height: 32px;

    svg {
      width: 100%;
      height: 100%;
    }

    &_fill-white {
      svg {
        fill: $white;
      }
    }
  }

  &__tab-content {
    width: 100%;
    margin-left: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_is-category {
      margin-left: 48px;
    }
  }

  &__icon-arrow {
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include transition-standard();

    svg {
      width: 100%;
      height: 100%;
      path {
        stroke: $white;
      }
    }

    &_up {
      transform: rotate(-180deg);
    }
  }
}
