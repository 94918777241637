.keys-utility {
  &__main-page {
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-left: auto;

    &-info {
      @include text-1;
      color: $blue;
    }
  
    &-download {
      margin-left: 6px;
      margin-right: 10px;
      scale: 1.2;
      transform: translate(0px, 2px);
    }
  }

  &__keys-page {
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    &-info {
      @include heading-4;
      margin-right: 24px;
    }

    .ant-btn {
      height: 38px;
      width: 110px;

      svg {
        margin-left: 10px;
        width: 20px;
        height: 20px;
        scale: 1.1;
        transform: translate(0px, -1px);
      }
    }
  }
}
