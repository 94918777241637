.user-page {
  &__back-button-wrapper {
    margin-bottom: 32px;
  }

  &__title {
    @include help-title-0;
    color: black;
    margin-bottom: 16px;
  }

  &__inputs-container {
    margin-bottom: 32px;
  }

  &__inputs-row {
    display: flex;
    margin-bottom: 32px;

    & > div {
      width: 100%;
      max-width: calc(50% - 12px);

      &:first-child {
        margin-right: 24px;
      }
    }

    &:first-child {
      margin-bottom: 24px;
    }
  }

  &__roles {
    margin-bottom: 16px;

    .simple-tabs {
      margin-bottom: 16px;
    }

    .ant-tabs-nav-more {
      display: none;
    }

    .tabs-custom__tab-container {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-template-rows: auto;
    }

    .tabs-custom__tab__data-text {
      max-width: 216px;
    }

    .ant-tabs-nav::before,
    .ant-tabs-ink-bar {
      display: none;
    }

    .ant-tabs-tab-active,
    .ant-tabs-tab {
      border: none !important;
      background: $blue-light !important;
    }

    &-select {
      width: 100%;
      max-width: calc(50% - 12px);
      margin-bottom: 16px;
      .select-multiple__title {
        @include help-title-0;
        color: black;
        margin-bottom: 16px;
      }
    }
  }

  &__info {
    &-title {
      @include text-0;
      color: $gray-medium;
      margin-bottom: 6px;
    }

    &-content {
      border-radius: 8px;
      border: 1px solid $gray-light-30;
      padding: 8px 16px;
      min-height: 178px;
    }

    &-item {
      display: flex;
      margin-bottom: 18px;
      height: 17px;

      &-label {
        @include text-0;
        min-width: 160px;
        color: $gray-medium;
        white-space: nowrap;
      }

      &-value {
        @include text-1;
        display: flex;
        flex-direction: row;
        white-space: nowrap;
        width: 100%;
      }

      &-invite {
        @include text-1;
        color: $blue;
        margin-left: auto;
        cursor: pointer;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@include breakpoint-down($grid-breakpoint-xl) {
  .user-page {
    &__info {
      &-content {
        height: 100%;
      }

      &-item {
        &-value {
          flex-direction: column;
        }

        &-invite {
          margin-left: 0;
        }
      }
    }
  }
}
