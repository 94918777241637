.subscribers-table {
  .ant-table-tbody > tr {
    > td {
      height: 100%;
      vertical-align: top;
      cursor: pointer;
    }
  }

  &__icon {
    cursor: pointer;
    width: 24px;
    height: 24px;
    margin-right: 12px;

    svg {
      width: 100%;
      height: 100%;

      path {
        stroke: $gray-medium;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      svg {
        path {
          stroke: $blue;
        }
      }
    }
  }

  &__name-data-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
  }

  &__status {
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
    margin-right: 10px;

    svg {
      width: 100%;
      height: 100%;
      fill: $gray-medium;
    }

    &_active {
      svg {
        fill: $green;
      }
    }

    &_inactive {
      svg {
        fill: $red;
      }
    }
  }

  &__object-data-container {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    row-gap: 20px;
    line-height: 20px;
  }

  &__info-object {
    position: relative;
  }

  &__info-object-name {
    position: absolute;

    &_name-longer {
      position: relative;
    }

    &_hide {
      visibility: hidden;
    }
  }
  
  &__info-object-flats {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &_name-longer {
      position: absolute;
    }

    &_hide {
      visibility: hidden;
    }
  }
}
