.notify {
  &__reminder {
    position: relative;

    &.notify__message {
      &::before {
        display: none;
      }
    }

    &-icon {
      position: absolute;
      left: 18px;
      top: 16px;
      scale: 1.1;
    }

    &-date {
      display: flex;
      max-height: 40px;
      margin-top: 8px;
    }

    &-date-picker {
      width: 100%;
      margin-right: 16px;

      .single-date-picker__content {
        height: 40px;
      }

      .single-date-picker__input-field {
        @include text-1;
      }
    }
  }
}
