.tabs {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap {
    &::before,
    &::after {
      box-shadow: none;
    }
  }

  &__tabs {
    height: 100%;

    .ant-tabs-content {
      height: 100%;
      position: initial;
    }

    .ant-tabs-nav::before,
    .ant-tabs-ink-bar {
      display: none;
    }

    .ant-tabs-nav-operations {
      display: none !important;
    }

    .ant-tabs-tab {
      color: $blue;
      padding: 12px 16px;

      &:hover {
        color: $blue;
      }

      &:focus {
        color: currentColor;
      }
    }

    .ant-tabs-tab-disabled {
      color: $gray-medium;

      &:hover {
        color: $gray-medium;
      }
    }

    .ant-tabs-tab-active {
      background: $blue-light;
      border-radius: 12px;
      color: $blue;

      .ant-tabs-tab-btn {
        color: $blue;
      }
    }
  }

  &__tab-title {
    @include tab-title-0;
  }

  &__tab {
    height: 100%;
  }

  .ant-tabs-nav-wrap {
    border-radius: 10px;
  }

  .ant-tabs-nav {
    position: relative;
    overflow: hidden;
  }

  &__tabs-button {
    position: absolute;
    width: 46px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $gray-light-30;
    background-color: $white;
    border-radius: 10px;
    cursor: pointer;
    z-index: 10;
    user-select: none;

    &_left {
      transform: translate(0, -50%);
    }

    &_right {
      transform: translate(-100%, -50%);
    }

    &_hide {
      display: none;
    }
  }
}
