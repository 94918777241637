.dispatcher-info {
  border-radius: 8px;
  border: 1px solid $gray-light-30;
  margin-right: 24px;
  min-height: 100%;
  min-width: 330px;

  &__title {
    padding: 13px 16px;
    display: flex;
    border-bottom: 1px solid $gray-light-30;

    &-label {
      @include help-title-0;
    }
  }
}
