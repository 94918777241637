.flat-base-tab {
  &__info {
    margin-bottom: 32px;
  }

  .information-block__section-item-value {
    width: 100%;
  }

  &__contact {
    display: flex;
    width: 100%;

    &-icon {
      margin-left: auto;
      cursor: pointer;

      svg {
        path {
          stroke: $gray-medium;
        }
      }

      &:hover {
        svg {
          path {
            stroke: $blue;
          }
        }
      }
    }
  }

  &__tariff {
    color: $blue;
    cursor: pointer;
  }

  .editable-table__data-container {
    max-height: max-content;
    min-height: 60px;
  }

  &__title {
    margin-bottom: 25px;
    display: flex;
    align-items: center;

    &-label {
      @include help-title-0;
    }

    &-btn {
      margin-left: auto;
    }
  }

  &__search {
    margin-bottom: 32px;
  }
}
