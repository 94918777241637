.systems {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__warning {
    margin-bottom: 16px;
  }

  &__count {
    margin-top: 8px;
    @include text-1;
    color: $gray-medium;
    text-align: end;
  }

  &__cell {
    display: flex;
    flex-direction: column;
    margin-left: 24px;

    .input-search {
      margin-top: auto;
    }
  }

  &__select {
    max-width: 400px;
  }

  &__button {
    padding: 13.5px 20px;
    width: auto;

    &-icon {
      svg {
        @include transition-standard();
        scale: 0.7;
        path {
          stroke: $white;
        }
      }
    }

    &.ant-dropdown-open {
      .systems__button-icon {
        svg {
          transform: rotate(-180deg);
        }
      }
    }
  }

  &__title {
    @include heading-1;
    color: $black;
  }
}
