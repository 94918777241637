.select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  &__title-info-icon {
    width: 16px;
    height: 16px;
    margin-left: 8px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    @include input-title-0;
    color: $gray-medium;
    margin-bottom: 6px;
    display: flex;
  }

  &__title-required {
    color: $red;
  }

  &__dropdown {
    padding: 0;
    border: 1px solid $gray-light-30;
    border-radius: 8px;
    box-shadow: none;
    filter: drop-shadow(0px 2px 4px rgba($black, 0.15));
  }

  &__dropdown-content {
    display: flex;
    flex-direction: column;
    background: $white;
    width: 100%;
    max-height: 286px;
  }

  &__dropdown-item {
    width: 100%;
    @include input-text-0;
    padding: 16px;
    background: $white;
    border-bottom: 1px solid $gray-light-30;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    hyphens: auto;

    &:last-child {
      border: none;
    }

    &:hover,
    &:focus {
      color: $black;
      background: $gray-light-10;
    }

    &_disabled {
      background: $white;
      color: $black;
      opacity: 0.5;
      cursor: auto;
    }

    &_selected {
      font-weight: 600;
      background: $blue-light;
      color: $black;
    }
  }

  &__select {
    width: 100%;

    .ant-select-selector {
      @include input-text-0;
      color: $black;
      background: $white;
      border: 1px solid $gray-light-30 !important;
      border-radius: 8px !important;
      padding: 14.5px 16px !important;
      min-height: 48px;
      box-shadow: none !important;
      height: auto !important;

      &:after {
        line-height: normal !important;
      }
    }

    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      text-overflow: ellipsis;
      margin: auto 0 0 0;
      max-width: 100%;
      padding-right: 18px !important;
      line-height: normal !important;

      &:after {
        content: none !important;
      }
    }

    .ant-select-arrow {
      height: 100%;
      top: 0;
      bottom: 0;
      right: 16px;
      margin: 0;
      padding: 0;

      svg {
        transition: all 0.3s;
      }
    }

    .ant-select-selection-search {
      display: flex;
      align-items: center;
      left: 16px !important;
      right: 16px !important;

      .ant-select-selection-search-input {
        @include input-text-0;
        color: $black;
      }
    }
  }

  .ant-select-disabled {
    .ant-select-selector {
      color: $black !important;
      background: $white !important;
      border: 1px solid $gray-light-30 !important;
    }
  }

  &__default-disabled {
    .ant-select-disabled {
      .ant-select-selector {
        color: $gray-500 !important;
        background: $gray-light-10 !important;
        border: 1px solid rgba($black, 0.1) !important;
      }

      .ant-select-arrow {
        opacity: 0.4;
        svg {
          path {
            stroke: $gray-medium;
          }
        }
      }
    }
  }

  .ant-select-open {
    .ant-select-selection-item {
      color: $black;
    }

    .ant-select-arrow {
      svg {
        transform: rotate(-180deg);
      }
    }
  }

  &_error {
    .select__select .ant-select-selector,
    .select .ant-select-disabled .ant-select-selector {
      border-color: $red !important;
      background: #fff2f2 !important;
    }
  }

  &__icon-clear-wrapper {
    z-index: 1;
    position: absolute;
    top: 34px;
    right: 41px;
    cursor: pointer;
    width: 24px;
    height: 24px;
    opacity: 1;
    background-color: $gray-light-10;

    &_search {
      right: 9px;
    }

    svg {
      width: 12px;
      height: 12px;

      path {
        fill: $gray-medium;
      }
    }
  }

  &__clear-icon {
    .ant-select-selection-item {
      max-width: calc(100% - 22px);
    }
  }

  &__hide-dropdown {
    .ant-select-dropdown {
      display: none;
    }
  }
}
