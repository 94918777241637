.expandable-block {
  &__title {
    display: flex;
    cursor: pointer;

    &-text {
      @include text-1;
      font-weight: 700;
      margin-bottom: 16px;
    }

    &-arrow {
      @include transition-standard();
      transform: translate(8px, -3px);

      &_up {
        transform: translate(8px, -12px) rotate(-180deg);
      }
    }
  }
}
