.tooltip {
  &__wrapper {
    cursor: pointer;
  }

  .ant-tooltip-inner {
    background: rgba($black, 0.7);
    border-radius: 8px;
    padding: 8px;
    text-align: left;
    color: $white;
    @include tooltip-text-0;
  }

  .ant-tooltip-arrow-content::before {
    background: rgba($black, 0.7);
  }
}
