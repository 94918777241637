.add-message-modal {
  &__button-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__inputs {
    display: flex;
    margin-top: 16px;

    .checkbox {
      min-width: 223px;
    }

    .single-date-picker {
      width: 100%;

      &__input-field {
        min-width: 230px;
        white-space: nowrap;
        @include text-1;
      }
    }
  }

  &__wrapper {
    .ant-modal-header {
      padding-bottom: 0;
    }

    .ant-modal-title {
      min-height: 32px;
      padding: 0;
    }

    .ant-modal-body {
      padding-top: 16px;
    }

    .ant-modal-footer {
      padding-top: 40px;
    }
  }

  &__notify {
    display: flex;
    margin-top: 16px;

    &-label {
      @include text-0;
    }

    &-icon {
      margin-right: 10px;
    }
  }
}
