.system-base-tab {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__content-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
    row-gap: 24px;
    grid-template-rows: auto;

    &_mt {
      margin-top: 24px;
    }

    &_3 {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &_4 {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    &_indent-top {
      margin-top: 40px;
    }

    &_indent-small-top {
      margin-top: 16px;
    }
  }

  &__select {
    width: 212px;
  }

  &__link {
    cursor: pointer;
    color: $blue;
  }

  &__subtitle {
    @include text-4;
    color: $black;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  &__settings-container {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
    padding: 16px;
    background: $blue-light;
    border-radius: 8px;
  }

  &__setting-block {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    row-gap: 16px;

    &_flex {
      display: flex;
      flex-direction: column;
    }

    .ant-checkbox-wrapper {
      margin: 0;
    }
  }

  &__setting-input {
    width: 255px;
  }

  &__setting-title {
    @include input-title-0;
    color: $gray-medium;
  }

  &__object-key-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $blue-light;
    border-radius: 8px;
    padding: 16px;
  }

  &__key-block {
    padding: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    @include text-1;
  }

  &__key-item {
    max-width: 300px;
    margin-top: 16px;
    @include text-1;
  }

  &__contact-item {
    max-width: 365px;
  }

  &__key-block-icon {
    svg {
      circle {
        fill: $white;
      }

      path {
        fill: $blue;
        stroke: $blue;
      }
    }
  }

  &__modal {
    .ant-modal-header {
      padding-right: 68px;
      padding-bottom: 0;

      .ant-modal-title {
        font-weight: 700;
      }
    }

    .ant-modal-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  &__tariff-empty {
    width: 376px;
  }

  &__ud-container {
    padding: 24px 20px;
    background: $blue-light;
    border-radius: 8px;
  }

  &__picker {
    max-width: 260px;

    .ant-picker-footer-extra {
      padding: 0;
    }

    .single-date-picker__button-container .button {
      margin-left: 0 !important;
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }

    .single-date-picker__dropdown .ant-picker-panel-container {
      padding: 12px;
    }

    .ant-picker-time-panel .ant-picker-content {
      height: 254px;
    }

    .ant-picker-time-panel-cell {
      width: 72px;
    }

    .single-date-picker__dropdown .ant-picker-panel-container .ant-picker-time-panel-column {
      margin: 0;
    }
  }

  &__security-code {
    max-width: 260px;
  }

  &__ud-inputs {
    display: flex;
    margin-bottom: 16px;

    & > div,
    & > label {
      width: 100%;
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__transfer {
    &-copy {
      cursor: pointer;
      height: 24px;
      width: 24px;
    }

    &-cancel {
      color: $red;
      @include text-1;
      font-weight: 400;
      margin-top: 20px;
      cursor: pointer;
    }

    &-container {
      min-height: 200px;
      background: $blue-light;
      border-radius: 8px;

      .input {
        width: 100%;
        max-width: 370px;
      }

      .input-description {
        color: $gray-medium;
      }
    }
  }

  &__tariff-container {
    padding: 24px 20px;
    background: $blue-light;
    border-radius: 8px;
  }

  &__tariffs-settings {
    &-content {
      display: flex;
    }

    &-block {
      width: 100%;
    }

    &-footer {
      margin-top: 3px;
      display: flex;
      @include text-1;

      .ant-btn {
        margin-left: auto;
        height: 38px;
      }
    }
  }

  &__tariff-item {
    @include text-1;
    margin-bottom: 17px;
    display: flex;
  }

  &__status-icon {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    position: relative;
    top: 4px;
    margin-right: 10px;

    &_red {
      background-color: $red;
    }

    &_green {
      background-color: $green;
    }
  }

  &__bold {
    font-weight: bold;
  }

  &__tooltip-icon {
    margin-left: 5px;
    position: relative;
    top: 3px;
    width: 12px;
    height: 12px;

    svg {
      circle {
        fill: $white;
      }
    }
  }
}
