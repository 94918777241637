.single-date-picker {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__title-container {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
  }

  &__title-info-icon {
    width: 16px;
    height: 16px;
    margin-left: 8px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__error {
    @include input-error-text-0;
    color: $red;
    margin-top: 6px;
  }

  &__title {
    @include input-title-0;
    color: $gray-medium;

    &-required {
      color: $red;
    }
  }

  .ant-picker-footer-extra:not(:last-child) {
    border: none;
  }

  &__button-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 8px;

    .button {
      margin-left: 8px !important;
    }
  }

  &__content {
    position: relative;
    width: 100%;
    height: auto;
  }

  .ant-picker-disabled {
    background: $white;
    border-color: $gray-light-30;
  }

  &__default-disabled {
    .ant-picker-disabled {
      background: $gray-light-10;
      border-color: $gray-light-30;
    }
  }

  &__error {
    .single-date-picker__input-field {
      border: 1px solid $red !important;
      background: $red-light !important;
    }
  }

  &__input-field {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    padding: 12px 36px 12px 16px;
    border: 1px solid $gray-light-30;
    border-radius: 8px;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    z-index: 1;
    background: $white;
    overflow: hidden;
    @include text-1;

    &:hover {
      border-color: $gray-light-30;
    }

    &_focus {
      border-color: $blue;

      &:hover {
        border-color: $blue;
      }
    }

    &-placeholder {
      color: $gray-medium;
    }

    &_disabled {
      color: $gray-medium;
      cursor: not-allowed;

      svg {
        path {
          opacity: 0.4;
        }
      }
    }
  }

  &__input-filed-text {
    min-width: 0;
    overflow: hidden;
    white-space: nowrap;
    @include table-body-text-0;
    color: $black;
    text-overflow: ellipsis;
    @include transition-standard();

    &_placeholder {
      color: $gray-500;
    }
  }

  &__input-field-icon-container {
    position: absolute;
    right: 16px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__input-filed-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__input-filed-icon_clear {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 12px;
    left: 0;
    bottom: 12px;
    background: $white;
    opacity: 0;
    z-index: 1;
    @include transition-opacity-standard();


    &_show {
      opacity: 1;
    }

    &:hover {
      opacity: 1;
    }
  }

  .ant-picker-date-panel .ant-picker-body {
    padding: 0 12px;
  }

  .ant-picker-time-panel {
    overflow: hidden;
    .ant-picker-header {
      display: none;
    }

    .ant-picker-time-panel-column {
      height: 272px;

      &::after {
        height: 18px;
      }
    }
  }

  &__field {
    visibility: hidden;
    width: 100%;
    padding: 12px 16px;
    border: 1px solid $gray-light-30;
    border-radius: 8px;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    z-index: 0;

    &:hover {
      border-color: $gray-light-30;
    }

    .ant-picker-suffix {
      margin-left: auto;
    }

    .ant-picker-clear {
      right: 16px;
      width: 16px;
      height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: none;

      svg {
        opacity: 0;
      }

      &:hover {
        background: $white;

        svg {
          opacity: 1;
        }
      }
    }

    .ant-picker-active-bar {
      display: none;
    }

    .ant-picker-input {
      width: auto;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @include table-body-text-0;
      color: $black;

      input {
        width: 100%;
        min-width: 0;
        display: block;

        &:disabled {
          color: $black;
        }
      }
    }

    &_clear {
      .ant-picker-input {
        display: none;
        width: 100%;

        &:first-child {
          display: flex;
        }
      }

      .ant-picker-range-separator {
        display: none;
      }
    }
  }

  .ant-picker-month-btn,
  .ant-picker-year-btn {
    pointer-events: none;
  }

  .ant-picker-focused {
    border-color: $blue;
  }

  .ant-picker-footer {
    border: none;
  }

  .ant-picker-ranges {
    display: none;
  }

  .ant-picker-header-super-prev-btn,
  .ant-picker-header-super-next-btn {
    display: none;
    &:hover {
      display: none;
    }
  }

  &__footer {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  tbody {
    tr:last-child {
      display: none;
    }
  }

  &__dropdown {
    @include text-2;
    font-feature-settings: normal;
    color: $black;

    .ant-picker-header {
      border: none;

      &-view {
        font-weight: bold;
      }
    }

    .ant-picker-panel-container {
      background: $white;
      box-shadow: 0 14px 44px rgba($black, 0.25);
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      padding: 19px;

      .ant-picker-panel {
        &-focused {
          border: none;
        }
      }

      .ant-picker-cell {
        color: $black;

        &-disabled {
          color: $gray-medium;

          &::before {
            display: none;
          }
        }

        &-inner {
          border-radius: 100%;
          height: 32px;
          width: 32px;
          padding-top: 5px;

          &:first-child {
            transform: translate(0, 0px);
          }

          &::before {
            border-radius: 100%;
          }
        }
      }

      .ant-picker-time-panel {
        &-column {
          width: 80px;
          margin: 0 10px 0 30px;
          padding-right: 10px;

          & > li.ant-picker-time-panel-cell {
            &-disabled {
              .ant-picker-time-panel-cell-inner {
                color: $gray-medium;
              }
            }

            .ant-picker-time-panel-cell-inner {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0;
              border: 1px solid $gray-light-50;
            }
          }
        }
      }
    }
  }
}
