.object-keys {
  .editable-table__data-container {
    min-height: auto;
    height: auto;
    padding: 0 12px;
  }

  &__table-entrance {
    transform: translate(0px, -10px);
  }

  &__keys-column {
    max-width: 0;
    overflow-x: scroll;
    overflow-y: hidden;

    .editable-table__data-container {
      padding: 0;
    }

    &::-webkit-scrollbar {
      background: inherit !important;
    }
  }

  &__cell-custom {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    &_bold {
      font-weight: 700;
    }
  }

  &__icon {
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__tooltip {
    max-width: 120px;

    .ant-tooltip-inner {
      padding: 8px;
      text-align: left;
      @include error-text-0;
      letter-spacing: initial;
    }
  }

  .ant-table-tbody > tr {
    > td {
      vertical-align: middle;
      height: 60px;
    }
  }

  .ant-table-thead > tr {
    > th {
      vertical-align: bottom;

      &:first-child {
        vertical-align: middle;
      }
    }
  }

  &__icon-container {
    display: flex;
    justify-content: center;
    flex: 3;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        svg {
          path {
            stroke: $blue;
          }
        }
      }
    }
  }

  &__last-col {
    display: flex;
    align-items: center;
  }

  &__arrow-col {
    width: 0;
    padding: 0 !important;
  }

  .tabs__tabs-button {
    width: 33px;
    height: 33px;
    z-index: 1;

    &_left {
      transform: translate(4px, -52%);
    }

    &_right {
      transform: none;
      position: relative;
      margin-left: 2px;
    }

    &_hide {
      visibility: hidden;
      pointer-events: none;
      display: flex;
    }
  }
}
