.feedback-table {
  width: 100%;
  position: relative;

  &__nowrap {
    white-space: nowrap;
  }

  &__menu_active {
    svg {
      path {
        stroke: $blue;
        fill: $white;
      }
      circle {
        stroke: $blue;
        fill: $white;
      }
    }
  }

  &.editable-table {
    .ant-table-cell {
      cursor: pointer;
      .editable-table__data-container,
      .editable-table__icon-container {
        padding: 11px 16px;
        min-height: 56px;
        hyphens: auto;
      }
    }

    .ant-table-thead > tr > th {
      padding: 17px 12px;
    }
  }

  &__user {
    display: flex;
    flex-direction: column;
    align-items: baseline;

    &-fio {
      &_active {
        color: blue;
      }
    }
  }

  &_absolute {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 23px;
    background-color: white;
    padding: 16px 0;
  }

  tbody tr {
    .ant-table-cell {
      &:first-child {
        min-width: 140px;
        padding-left: 40px;
      }
    }
  }

  thead tr {
    .ant-table-cell {
      &:first-child {
        min-width: 140px;
        padding-left: 50px;
      }
    }
  }
}
