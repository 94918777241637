.feedback-history {
  &__content {
    &-filters {
      display: flex;
      margin-bottom: 24px;

      .button {
        margin-top: auto;
        height: 48px;
      }

      .select {
        width: 100%;
        margin-right: 16px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__event {
    margin-bottom: 4px;
    padding: 12px 12px 0 12px;

    &_user {
      background-color: $gray-light-50;
      border-radius: 8px;

      .feedback-history__event-content {
        border: none;
        padding-bottom: 0;
      }
    }

    &-title {
      @include text-1;
      font-weight: 700;
      margin-bottom: 12px;
    }

    &-body {
      display: flex;
    }

    &-tag {
      @include text-0;
      color: $gray-medium;
      width: 20%;
      max-width: 118px;
    }

    &-content {
      width: 80%;
      padding-bottom: 8px;
      border-bottom: 1px solid $gray-light-30;
    }

    &-message {
      @include text-1;
      margin-top: 16px;

      &:first-child {
        margin-top: 0;
      }

      strong {
        font-weight: 700;
      }
    }

    &-comment {
      border-radius: 8px;
      border: 1px solid $gray-light-30;
      padding: 12px 16px;
      margin-top: 16px;
      background-color: $white;
      @include text-1;
    }

    &-date {
      @include text-0;
      color: $gray-medium;
      font-size: 12px;
      margin-top: 8px;
    }
  }

  &__empty-text {
    @include text-0;
    color: $gray-medium;
  }

  &__score {
    margin-top: 16px;
  }

  .simple-gallery {
    margin-top: 16px;

    &__item {
      height: 98px;
      margin-right: 10px;
    }

    .tabs__tabs-button {
      &_left {
        top: 50px;
        left: -6px;
        scale: 0.7;
      }
  
      &_right {
        top: 50px;
        right: -2px;
        scale: 0.7;
      }
    }
  }
}
