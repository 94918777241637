.simple-gallery {
  position: relative;
  z-index: 1;

  &__loader {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__count {
    @include text-0;
    margin-bottom: 4px;
    color: $gray-medium;
  }

  &__download-button {
    display: flex;
    align-items: end;
    @include text-1;
    color: $blue;
    cursor: pointer;
    margin-top: 8px;

    &-icon {
      margin-left: 7px;
      transform: scale(1.3);
      position: relative;
      top: 2px;
    }
  }

  &__container {
    display: flex;
    width: 100%;
    padding-bottom: 7px;
    overflow: scroll;
  }

  &__item {
    height: 180px;
    min-width: fit-content;
    margin-right: 32px;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;

    &-download-icon {
      opacity: 0;
      cursor: pointer;
      position: absolute;
      bottom: 13px;
      left: 14px;
      @include transition-opacity-standard();
    }

    &-img-icon {
      opacity: 0;
      position: absolute;
      top: 13px;
      left: 6px;
      @include transition-opacity-standard();
    }

    &-name {
      cursor: default;
      position: absolute;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      top: 15px;
      left: 35px;
      opacity: 0;
      @include text-1;
      font-weight: 700;
      color: white;
      @include transition-opacity-standard();
    }

    &-size {
      cursor: default;
      position: absolute;
      top: 35px;
      left: 35px;
      opacity: 0;
      @include text-0;
      color: white;
      @include transition-opacity-standard();
    }

    &-shadow {
      position: absolute;
      background-color: black;
      width: 100%;
      height: 100%;
      opacity: 0;
      @include transition-opacity-standard();
    }

    img,
    video {
      height: 100%;
      object-fit: cover;
      position: relative;
      z-index: -1;
    }

    &:hover {
      .simple-gallery__item-download-icon {
        opacity: 1;
      }

      .simple-gallery__item-size {
        opacity: 1;
      }

      .simple-gallery__item-img-icon {
        opacity: 1;
      }

      .simple-gallery__item-name {
        opacity: 1;
      }

      .simple-gallery__item-shadow {
        opacity: 0.4;
      }
    }
  }

  .tabs__tabs-button {
    &_left {
      top: 90px;
      transform: none;
    }

    &_right {
      top: 90px;
      left: auto;
      right: 0px;
      transform: none;
    }
  }
}
