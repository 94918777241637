.confirm-modal {
  &__text {
    @include text-1;
    margin-top: 28px;
  }

  .ant-modal-content {
    width: 416px;
  }

  .ant-modal-header {
    padding-bottom: 0;
  }

  .ant-modal-title {
    padding-bottom: 0;
    padding-right: 16px;
  }
}
