.skin-index-modal {
  &__button-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__wrapper {
    .ant-modal-header {
      padding-bottom: 0;
      border-radius: 20px;
    }

    .ant-modal-content {
      overflow: visible !important;
    }

    .ant-modal-title {
      padding: 0;
      width: 80%;
      margin-bottom: 24px;
    }

    .ant-modal-footer {
      padding-top: 40px;
    }
  }
}
