.subsection-modal {
  &__buttons-container {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
  }

  &__button {
    padding: 16px 66px;
  }
}
