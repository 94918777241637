.subscriber-object-address {
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  border-bottom: 1px solid $gray-300;

  &__object-select-container {
    display: flex;
    align-items: flex-end;
  }

  &__object-select {
    width: 50%;
  }

  &__order {
    width: 32px;
    min-width: 32px;
    height: 43px;
    @include text-4;
    color: $black;
    display: flex;
    align-items: center;
  }

  &__row {
    padding-left: 32px;
  }

  &__address {
    display: flex;
    align-items: center;
    margin-top: 24px;
  }

  &__building-select {
    width: 100%;
  }

  &__flat-select {
    min-width: 94px;
    margin-left: 24px;

    .ant-select-selection-item {
      text-overflow: inherit !important;
    }
  }

  &__property {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 1fr;
    grid-template-rows: 1fr;
    column-gap: 24px;
    margin-top: 24px;
    padding-left: 32px;

    &_create-mode {
      grid-template-columns: 1fr 2fr 2fr;
    }
  }

  &__checkbox-wrapper {
    width: 100%;
    height: 100%;
    transform: translate(0, 23px);
  }

  &__checkbox {
    height: 48px;
    display: flex;
    align-items: center;
  }

  &__checkbox-wrapper_mt {
    align-items: center;
  }

  &__button-container {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-bottom: auto;
    margin-top: 21px;
  }

  &__button {
    width: 212px;
    display: flex;
    justify-content: center;
    padding: 13.5px 20px;
  }

  &__not-found {
    padding: 14px;
    align-items: flex-start;
  }

  &__not-found-text {
    @include error-text-0;
    color: $gray-medium;
    margin: 0;
  }

  &__subscription {
    @include text-1;
    margin-top: 16px;

    &-type {
      color: $blue;
      cursor: pointer;
      margin-left: 5px;
    }
  }
}
