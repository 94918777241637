.access-group-table {
  width: 100%;
  height: 100%;
  position: relative;

  .ant-table-thead {
    .ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last {
    }
  }

  &__icon-without-fill svg {
    fill: none;
  }

  &__error {
    height: calc(100vh - 520px);

    .ant-btn {
      margin-top: 10px;
      height: 38px;
    }
  }

  &__tooltip {
    max-width: 120px;
  }

  &__tooltip-text {
    display: flex;
    flex-direction: column;
  }

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 120px;
    flex-direction: column;

    &-content {
      display: flex;
      margin-bottom: 6px;
    }
  }

  &__icon {
    height: 16px;
    width: 16px;
    transform: translate(-3px, 2px);
  }

  &__title-info {
    @include table-header-text-0;
    color: $gray-medium;
    cursor: pointer;
    position: relative;
    top: 2px;
    height: 100%;
    width: 100%;
    text-align: left;
  }

  &__title-label {
    margin-right: 8px;
  }

  &__title-checkbox-p {
    .ant-checkbox-checked .ant-checkbox-inner::after {
      height: 1px !important;
      width: 10px !important;
      position: absolute;
      top: 7px;
      border: 2px solid #fff;
      border-top: initial;
      border-left: initial;
      transform: scale(1);
    }
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    border-right: 1px solid $gray-light-30;
    background-color: $white;

    &:last-child {
      border-right: 0;
    }
  }

  .ant-table-container table > thead > tr > th {
    padding: 11px 12px 6px 12px;
    font-weight: 400;
  }

  .ant-table-wrapper {
    height: 100%;

    .ant-spin-nested-loading {
      height: 100%;

      .ant-spin-container {
        height: 100%;
        display: flex;
        flex-direction: column;

        .ant-table {
          max-height: 100%;
          overflow-x: auto;
          overflow-y: auto;

          .ant-table-container {
            height: 100%;

            .ant-table-content {
              height: 100%;
            }
          }

          &:hover,
          &:focus {
            &::-webkit-scrollbar-thumb {
              visibility: visible;
            }
          }

          &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
            background: $gray-light-30;
            cursor: pointer;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: $gray-medium;
            transition: 0.3s all linear;
            visibility: hidden;
            cursor: pointer;
          }
        }
      }
    }
  }

  .ant-table-cell-fix-left-last {
    min-width: 120px;
  }

  &__key {
    border-right: 1px solid $gray-light-30;
    justify-content: left;
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-items: baseline;

    &-name {
      white-space: nowrap;
      @include text-0;
      font-size: 10px;
      color: $gray-medium;
    }

    &-alias {
      @include text-1;
    }
  }

  .editable-table__data-container {
    justify-content: center;
    min-height: 59px;
    padding: 10px 12px;

    &_name {
      border-right: 1px solid $gray-light-30;
      justify-content: left;
      background-color: $white;
    }
  }

  .tabs__tabs-button {
    &_left {
      top: 10px;
      left: -23px;
      transform: none;
    }

    &_right {
      top: 10px;
      left: auto;
      right: 7px;
      transform: none;
    }
  }
}
