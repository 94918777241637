.subscriber-data {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
  row-gap: 24px;

  &__input {
    padding: 13.5px 16px;
  }
}
